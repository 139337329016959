import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const ROW_HEIGHT = 90;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'wrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    height: 5.25 * ROW_HEIGHT,
  },
  title: {
    color: '#ffffff',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: '#014062',
  },
}));

export default function ImageRow(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList className={classes.imageList} cols={8} rowHeight={ROW_HEIGHT}>
        {props.data.map((item, idx) => (
          <ImageListItem key={`${props.prefix}_${item.name}_${idx}`}>
            <img
              alt=''
              src={item.url}
              onClick={props.onClick.bind(null, item)}
            />

            <ImageListItemBar
              actionIcon={
                <IconButton
                  onClick={(e) => {
                    props.iconAction(item);
                  }}
                >
                  <ShoppingCartIcon className={classes.icon} />
                </IconButton>
              }
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
