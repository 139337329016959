import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  themes: [],
  events: [],
  eventTypeId: 1,
  themeId: null,
};

const getThemeIdForAll = (themes) => {
  if(themes?.length === 0) {
    return null;
  }
  for( let t of themes) {
    if(t.name === 'All') {
      return t.id;
    }
  }
  return null;
}

export default function cardFormReducer(state = initialState, action) {
  switch (action.type) {
    case 'card-form/set': {
      return {
        ...state,
        ...action.value,
      };
    }
    case 'card-form/setEventId': {
      return {
        ...state,
        eventTypeId: action.value,
      };
    }
    case 'card-form/setThemeId': {
      return {
        ...state,
        themeId: action.value,
      };
    }
    case 'card-form/fetchThemes/fulfilled': {
      return {
        ...state,
        themes: action.payload,
        themeId: getThemeIdForAll(action.payload),
      };
    }
    case 'card-form/fetchEvents/fulfilled': {
      return {
        ...state,
        events: action.payload,
        eventTypeId: action.payload[0]?.id,
      };
    }
    case 'card-catalog/fetchThemes/fulfilled': {
      return {
        ...state,
        themes: action.payload,
        themeId: getThemeIdForAll(action.payload),
      };
    }
    case 'card-catalog/fetchEvents/fulfilled': {
      return {
        ...state,
        events: action.payload,
        eventTypeId: action.payload[0]?.id,
      };
    }
    default:
      return state;
  }
}

export const fetchCardThemes = createAsyncThunk(
  'card-form/fetchThemes',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/themes/${args.eventType}`, {
      headers: headers,
    });
    return response.data;
  }
);

export const fetchCardEvents = createAsyncThunk(
  'card-form/fetchEvents',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/event_types`, {
      headers: headers,
    });
    return response.data;
  }
);
