import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import ListSubheader from '@material-ui/core/ListSubheader';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  nav: {
    textAlign: 'end',
  },
  bar: {
    backgroundColor: '#89828280',
  },
}));

const CardImageList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} cols={4} className={classes.imageList}>
        <ImageListItem key='Subheader' cols={4} style={{ height: 'auto' }}>
          <ListSubheader component='div' className={classes.nav}>
            <IconButton
              disabled={props.disabledPrevious}
              onClick={props.clickPrevious}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton disabled={props.disabledNext} onClick={props.clickNext}>
              <NavigateNextIcon />
            </IconButton>
          </ListSubheader>
        </ImageListItem>
        {props.cards.map((item) => (
          <ImageListItem key={item.id}>
            <img src={item.url} alt={item.name} />
            <ImageListItemBar
              title={item.token}
              className={classes.bar}
              actionIcon={
                <IconButton
                  onClick={props.onClickImage.bind(null, item)}
                  aria-label={`info about ${item.name}`}
                  className={classes.icon}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export { CardImageList };
