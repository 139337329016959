import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  cardFilters: {
    display: 'flex',
    margin: theme.spacing(2, 1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const EventThemeSelection = (props) => {
  const classes = useStyles();
  const {
    events,
    eventTypeId,
    onEventSelect,
    disabled,
  } = props;

  return (
    <div className={classes.cardFilters}>
      <FormControl className={classes.formControl}>
        <InputLabel>Event</InputLabel>
        <Select
          value={events?.length > 0 ? eventTypeId : ''}
          disabled={disabled}
          onChange={onEventSelect}
        >
          {events?.map((x, i) => (
            <MenuItem value={x.id} key={i}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export { EventThemeSelection };
