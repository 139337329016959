import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[1],
    width: 400,
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    margin: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center',
    top: '35%',
    left: '35%',
  },
}));

const DeleteModal = (props) => {
  const classes = useStyles();
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <div className={classes.root}>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='body1' style={{ padding: 0 }}>
              {props.message}
            </Typography>
          </Grid>
          <Grid
            container
            item
            justifyContent='center'
            direction='row'
            spacing={1}
          >
            <Grid item>
              <Button
                onClick={props.onClickCancel}
                color='default'
                variant='contained'
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={props.onClickRemove}
                color='secondary'
                variant='contained'
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export { DeleteModal };
