import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return (
    <MuiAlert
      elevation={6}
      variant='filled'
      onClose={props.onClose}
      severity={props.severity}
    >
      {props.message}
    </MuiAlert>
  );
};

const SnackbarAlert = (props) => {
  const { open, onClose } = props;

  return (
    <div>
      <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
        <Alert {...props} />
      </Snackbar>
    </div>
  );
};

export { SnackbarAlert };
