import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export default function unassignedTaskReducer(state = initialState, action) {
  switch (action.type) {
    case 'unassigned-task/fetch/fulfilled': {
      return action.payload;
    }
    default:
      return state;
  }
}

export const fetchUnassignedTasks = createAsyncThunk(
  'unassigned-task/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(
      `/api/v0/unassigned_tasks/${args.page}?results_per_page=500`,
      {
        headers: headers,
      }
    );
    return response.data;
  }
);
