import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CardCart } from './CardCart';
import { updateTask, assignTask } from '../../features/task/taskSlice';
import TASK_STATES from '../../constants/states.js';
import PATHS from '../../constants/paths';

const MAX_CARDS = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  selectedCard: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
  },
  deselectCard: {
    position: 'relative',
    float: 'right',
    top: 0,
    right: 0,
  },
  messages: {
    paddingTop: 8,
    paddingBottom: 2,
    margin: theme.spacing(1, 1),
  },
  shipit: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const CardSelectionContainer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { adminUserId, assigned, cart, task, token } = props;
  const [messageText, setMessageText] = useState('Write a Message');
  const handleMessageText = (event) => {
    setMessageText(event.target.value);
  };

  useEffect(() => {
    if (props?.message) {
      setMessageText(props.message);
    }
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const _createPayload = (status) => {
    let payload = {
      message: messageText,
      state: status,
    };
    if (cart.length > 0) {
      payload['card_id'] = cart[0]?.id;
      payload['cart'] = cart.map((x, i) => ({
        id: x.id,
        rank: i,
        card_id: x.token,
        image_name: x.path,
      }));
    }
    return payload;
  };

  const saveSelectedCard = () => {
    let payload = _createPayload(TASK_STATES.IN_PROGRESS);
    if (!assigned) {
      dispatch(
        assignTask({
          token: token,
          taskId: task.id,
          adminUserId: adminUserId,
        })
      );
    }
    dispatch(
      updateTask({
        taskId: task.id,
        token: token,
        payload: payload,
      })
    );
  };

  const shipSelectedCard = () => {
    let payload = _createPayload(TASK_STATES.COMPLETED);
    if (!assigned) {
      dispatch(
        assignTask({
          token: token,
          taskId: task.id,
          adminUserId: adminUserId,
        })
      );
    }
    payload.active = false;
    dispatch(
      updateTask({
        taskId: task.id,
        payload: payload,
        token: token,
      })
    );
    history.push(PATHS.tasks);
    dispatch({ type: 'selectCard/setCart', value: [] });
  };

  const handleRemoveFromCart = (index) => {
    if (index < MAX_CARDS) {
      dispatch({ type: 'selectCard/removeFromCart', value: index });
    }
  };

  return (
    <Grid item container direction='row' className={classes.root}>
      <Grid item xs={6}>
        <CardCart
          cart={cart}
          classes={classes}
          onCancelClick={handleRemoveFromCart}
        />
      </Grid>
      <Grid item container direction='column' xs={6} spacing={0}>
        <Grid item container direction='row' className={classes.messages}>
          <TextField
            id='outlined-multiline-static'
            label='Message'
            multiline
            rows={6}
            value={messageText}
            variant='outlined'
            onChange={handleMessageText}
            fullWidth={true}
          />
        </Grid>
        <Grid
          item
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
        >
          <Button
            variant='contained'
            color='primary'
            onClick={saveSelectedCard}
            className={classes.shipit}
          >
            Save
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={shipSelectedCard}
            className={classes.shipit}
            disabled={cart.length < MAX_CARDS}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { CardSelectionContainer };
