import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import {
  fetchCard,
  fetchCart,
} from '../../features/select-card/selectCardSlice';
import PATHS from '../../constants/paths';

const COLUMNS = [
  {
    field: 'sender_name',
    headerName: 'Sender',
    width: 150,
  },
  {
    field: 'recipient',
    headerName: 'Recipient',
    width: 150,
  },
  {
    field: 'recipient_name',
    headerName: 'Recipient Name',
    width: 200,
  },
  {
    field: 'age',
    headerName: 'Age',
    width: 120,
  },
  {
    field: 'event',
    headerName: 'Event',
    width: 150,
  },
  {
    field: 'delivery_date',
    headerName: 'Delivery Date',
    width: 180,
  },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    width: 200,
  },
];

export function TaskTable(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const rowClickEvent = (params) => {
    let task = params?.row || {};
    if (props?.taskId) {
      task = { ...task, id: task[props.taskId] };
    }
    let actionValue = {
      task: task,
      user: {
        id: params?.row?.admin_user_id || props.adminUserId,
        name: params?.row?.name || props.adminUserName,
      },
      assigned: props.assigned,
    };
    dispatch({ type: 'task/setTask', value: actionValue });
    dispatch(
      fetchCart({ token: props.token, taskId: params?.row[props.taskId] })
    );
    if (params?.row?.card_id) {
      dispatch(fetchCard({ token: props.token, cardId: params.row.card_id }));
    }
    history.push(PATHS.recommendations);
  };

  const getTableProps = () => {
    let params = {
      rows: props.rows,
      columns: COLUMNS,
      onRowClick: rowClickEvent,
    };
    if ('extraProps' in props) {
      params = {
        ...params,
        ...props.extraProps,
      };
    }
    return params;
  };

  return (
    <div style={{ height: props.height, width: '100%' }}>
      <DataGrid {...getTableProps()} />
    </div>
  );
}
