import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { CardForm } from './CardForm';

const CardModal = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, editable, enableTokenLookup } = props;

  useEffect(() => {
    if (props?.events?.length > 0) {
      dispatch({
        type: 'card-form/set',
        value: {
          events: props?.events,
          themes: props?.themes,
          eventTypeId: props?.eventTypeId,
          themeId: props?.themeId,
        },
      });
    }
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <div>
          <CardForm editable={editable} enableTokenLookup={enableTokenLookup} />
        </div>
      </Fade>
    </Modal>
  );
};

export { CardModal };
