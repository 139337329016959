import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { ReactComponent as Logo } from '../../logo.svg';

const useAppBarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgb(1, 64, 98)',
    position: 'relative',
  },
  title: {
    flexGrow: 1,
    color: 'white',
  },
  accountIcon: {
    color: '#ffffff',
    fontSize: 32,
  },
}));

const useAppBarLinkStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    textDecoration: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));

const AppBarLink = (props) => {
  const classes = useAppBarLinkStyles();
  return (
    <Typography variant='h6' className={classes.title}>
      <NavLink to={props.path} className={classes.root}>
        {props.title}
      </NavLink>
    </Typography>
  );
};

export const AdminPortalAppBar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useAppBarStyles();
  const auth = getAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickProfile = () => {
    setAnchorEl(null);
    history.push(props.paths.profile);
  };

  const logout = () => {
    setAnchorEl(null);
    dispatch({
      type: 'auth/set',
      value: {
        loggedIn: false,
        loading: false,
        redirect: false,
        user: {},
      },
    });
    auth.signOut();
  };

  return (
    <AppBar position='static' className={classes.root}>
      <Toolbar>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid
            item
            container
            direction='row'
            spacing={4}
            xs={6}
            alignItems='center'
            justifyContent='flex-start'
          >
            <Grid item>
              <Logo
                style={{
                  height: 48,
                  float: 'right',
                  fill: 'white',
                  marginTop: 8,
                }}
              />
            </Grid>
            <Grid item>
              <AppBarLink path={props.paths.tasks} title='Tasks' />
            </Grid>
            <Grid item>
              <AppBarLink path={props.paths.customers} title='Customers' />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={6}
          direction='row'
          justifyContent='flex-end'
        >
          <Grid
            container
            direction='row'
            spacing={2}
            justifyContent='flex-end'
            alignItems='center'
          >
            <Grid item>
              <IconButton onClick={handleClick}>
                <AccountCircleIcon className={classes.accountIcon} />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={clickProfile}>My Account</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
