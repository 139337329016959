import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import BrushIcon from '@material-ui/icons/Brush';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { AllTaskView } from '../components/tasks//AllTaskView';
import { UsersAdminView } from '../components/admin/UsersAdminView';
import { CardCatalog } from '../components/cards/CardCatalog';
import { PurchaseOrderView } from '../components/orders/PurchaseOrderView';
import { VendorView } from '../components/orders/VendorView';

const BaseItems = ['My Profile', 'My Tasks'];
const AdminItems = [
  'All Tasks',
  'Users',
  'Cards',
  'Vendors',
  'Purchase Orders',
];

const fetchIcon = (name) => {
  switch (name) {
    case 'My Profile': {
      return <AccountCircleIcon />;
    }
    case 'My Tasks': {
      return <ListIcon />;
    }
    case 'All Tasks': {
      return <ListIcon />;
    }
    case 'Users': {
      return <PeopleIcon />;
    }
    case 'Cards': {
      return <BrushIcon />;
    }
    case 'Vendors': {
      return <StorefrontIcon />;
    }
    case 'Purchase Orders': {
      return <ShoppingCartIcon />;
    }
    default:
      return <ListIcon />;
  }
};

const selectView = (name, user, classes) => {
  switch (name) {
    case 'Users': {
      return <UsersAdminView />;
    }
    case 'My Tasks': {
      return <AllTaskView userOnly={true} />;
    }
    case 'All Tasks': {
      return <AllTaskView />;
    }
    case 'Cards': {
      return <CardCatalog />;
    }
    case 'Vendors': {
      return <VendorView />;
    }
    case 'Purchase Orders': {
      return <PurchaseOrderView />;
    }
    default:
      return <MyProfileView user={user} className={classes.myProfile} />;
  }
};

const MyProfileView = (props) => {
  return (
    <Box className={props.className}>
      <Typography variant='h5'>
        <b>Name</b> {props.user?.first_name} {props.user?.last_name}
      </Typography>
      <Typography variant='h5'>
        <b>Email:</b> {props.user?.email_address}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 1,
  },
  drawer: {
    width: 180,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: 180,
      boxSizing: 'border-box',
    },
  },
  drawerItems: {
    overflow: 'auto',
    marginTop: 64,
  },
  myProfile: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
  },
  button: {
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const AdminUserDrawer = (props) => {
  const listItems = (isAdmin) => {
    const items = isAdmin ? [...BaseItems, ...AdminItems] : [...BaseItems];

    return items.map((item) => (
      <ListItem button key={item} onClick={props.onClick.bind(null, item)}>
        <ListItemIcon>{fetchIcon(item)}</ListItemIcon>
        <ListItemText primary={item} />
      </ListItem>
    ));
  };

  return (
    <Drawer variant='permanent' className={props.classes.drawer}>
      <Box className={props.classes.drawerItems}>
        <List>{listItems(props.isAdmin)}</List>
      </Box>
    </Drawer>
  );
};

const AdminUserView = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const [selectedView, setSelectedView] = useState('My Profile');

  const onItemClick = (name, event) => {
    setSelectedView(name);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AdminUserDrawer
        isAdmin={user?.user_group_id === 1}
        classes={classes}
        onClick={onItemClick}
      />
      <Box component='main' className={classes.main}>
        {selectView(selectedView, user, classes)}
      </Box>
    </Box>
  );
};

export { AdminUserView };
