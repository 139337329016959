import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

const CardInventoryRow = (props) => {
  const { printable, setPrintable, units, disabled } = props;

  return (
    <div>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={printable || false}
                  color='primary'
                  disabled={disabled}
                  onChange={(e) => setPrintable(e.target.checked)}
                />
              }
              label='Printable?'
              labelPlacement='end'
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant='body1' className={props.unitsClassName}>
            Available Units:
            {` ${units}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export { CardInventoryRow };
