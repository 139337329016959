import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { assignTask, reassignTask } from '../../features/task/taskSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    width: '95%',
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#e7e7e7',
  },
  reassign: {
    color: '#e7e7e7',
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const getAssignedTo = (assigned, userId, taskAdminUserId, taskAdminUser) => {
  if (assigned) {
    if (userId === taskAdminUserId) {
      return 'Me';
    }
    return `${taskAdminUser}`;
  }
  return 'Unassigned';
};

const AdminUserInfo = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const _reassignTask = () => {
    dispatch(
      reassignTask({
        token: props.token,
        taskId: props.taskId,
        adminUserId: props.adminUserId,
      })
    );
  };

  const _assignTask = () => {
    dispatch(
      assignTask({
        token: props.token,
        taskId: props.taskId,
        adminUserId: props.adminUserId,
      })
    );
  };

  const assignedTo = getAssignedTo(
    props.assigned,
    props.adminUserId,
    props.taskAdminUserId,
    props.taskAdminUser
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container direction='column' spacing={0}>
          <Grid
            item
            container
            direction='row'
            xs={12}
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='body2'>
              <b>Assigned To:</b> {assignedTo}
            </Typography>
            {props.assigned ? (
              <Chip
                size='small'
                label='Reassign'
                clickable
                color='primary'
                className={classes.reassign}
                onClick={_reassignTask}
                icon={<CancelRoundedIcon />}
              />
            ) : (
              <Chip
                size='small'
                label='Assign To Me'
                clickable
                color='primary'
                className={classes.reassign}
                onClick={_assignTask}
                icon={<AddCircleRoundedIcon />}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export { AdminUserInfo };
