import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { EditBar } from '../EditBar';

import { updateSenderById } from '../../features/sender/senderSlice';

const FIELDS = [
  { field: 'username', editable: false, label: 'Username' },
  { field: 'email', editable: true, label: 'Email' },
  { field: 'credits', editable: false, label: 'Credits' },
];

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(1),
  },
  long: {
    width: '35ch',
  },
  medium: {
    width: '25ch',
  },
  short: {
    width: '16ch',
  },
}));

const SenderForm = ({ senderId, sender, editable }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const status = useSelector((state) => state.sender.status);
  const [values, setValues] = useState({});
  const [updatedFields, setUpdatedFields] = useState([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (sender) {
      let data = FIELDS.reduce(
        (a, v) => ({ ...a, [v.field]: sender[v.field] }),
        {}
      );
      setValues(data);
    }
  }, [sender]);

  useEffect(() => {
    if (status === 'succeeded') {
      let data = FIELDS.reduce(
        (a, v) => ({ ...a, [v.field]: sender[v.field] }),
        {}
      );
      setValues(data);
      setUpdatedFields([]);
    }
  }, [status]);

  const onFieldEdit = (field) => (e) => {
    const updates = {
      ...values,
      [field]: e.target.value,
    };
    setValues(updates);
    setUpdatedFields([...updatedFields, field]);
  };

  const onClickSave = () => {
    if (user?.token && updatedFields) {
      let args = {
        token: user.token,
        body: updatedFields.reduce((a, v) => ({ ...a, [v]: values[v] })),
        senderId: senderId,
      };
      dispatch(updateSenderById(args));
    }
  };

  const onClickCancel = () => {
    setEditMode(false);
  };

  console.log(sender);
  return (
    <Box>
      <EditBar
        editMode={editMode}
        saveDisabled={false}
        setEditMode={setEditMode}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
      <Grid container direction='column' spacing={1} alignItems='flex-start'>
        {FIELDS.map((item, index) => (
          <Grid item key={`sender-form-${index}`}>
            <TextField
              className={classes.long}
              size='small'
              id={`sender-form-${item.field}`}
              label={item.label}
              value={values[item.field]}
              disabled={!(editMode && item.editable && editable)}
              onChange={onFieldEdit(item.field)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SenderForm;
