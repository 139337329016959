import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import recipientReducer from '../features/recipient/recipientSlice';
import recommendationsReducer from '../features/recommendations/recommendationsSlice';
import selectCardReducer from '../features/select-card/selectCardSlice';
import cardFormReducer from '../features/select-card/cardFormSlice';
import senderReducer from '../features/sender/senderSlice';
import tagsReducer from '../features/tags/tagSlice';
import taskReducer from '../features/task/taskSlice';
import adminUsersReducer from '../features/admin-users/adminUserSlice';
import vendorsReducer from '../features/vendors/vendorSlice';
import purchaseOrdersReducer from '../features/purchase-orders/purchaseOrderSlice';
import orderItemsReducer from '../features/purchase-orders/orderItemsSlice';
import cardCatalogReducer from '../features/card-catalog/cardCatalogSlice';
import taskTableReducer from '../features/task-table/taskTableSlice';
import assignedTaskReducer from '../features/assigned-tasks/assignedTaskSlice';
import unassignedTaskReducer from '../features/unassigned-tasks/unassignedTaskSlice';

export const store = configureStore({
  reducer: {
    selectCard: selectCardReducer,
    auth: authReducer,
    tasktable: taskTableReducer,
    unassigned: unassignedTaskReducer,
    tags: tagsReducer,
    task: taskReducer,
    recipient: recipientReducer,
    sender: senderReducer,
    recommendations: recommendationsReducer,
    assigned: assignedTaskReducer,
    adminUsers: adminUsersReducer,
    cardCatalog: cardCatalogReducer,
    vendors: vendorsReducer,
    purchaseOrders: purchaseOrdersReducer,
    cardForm: cardFormReducer,
    orderItems: orderItemsReducer,
  },
});
