import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

const EditBar = (props) => {
  const { editMode, setEditMode, onClickSave, onClickCancel, saveDisabled } =
    props;
  if (editMode) {
    return (
      <Grid item container direction='row' justifyContent='flex-end'>
        <IconButton disabled={saveDisabled} onClick={onClickSave}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={onClickCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
    );
  }
  return (
    <Grid item container direction='row' justifyContent='flex-end'>
      <IconButton onClick={() => setEditMode(true)}>
        <EditIcon />
      </IconButton>
    </Grid>
  );
};

export { EditBar };
