import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { OrderItemsGallery } from './OrderItemsGallery';
import { PurchaseOrderModal } from './PurchaseOrderModal';
import { PurchaseOrderTable } from './PurchaseOrderTable';
import { fetchVendors } from '../../features/vendors/vendorSlice';
import { fetchCardEvents } from '../../features/select-card/cardFormSlice';
import {
  fetchPurchaseOrders,
  createPurchaseOrder,
  updatePurchaseOrder,
} from '../../features/purchase-orders/purchaseOrderSlice';
const NOT_SELECTED = 'Order Details';
const useStyles = makeStyles((theme) => ({
  accordion: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
  },
  details: {
    height: 500,
  },
  orderDetails: {
    height: 500,
  },
}));

const PurchaseOrderView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const vendors = useSelector((state) => state.vendors.vendors);
  const purchaseOrders = useSelector(
    (state) => state.purchaseOrders.purchaseOrders
  );
  const purchaseOrderCreated = useSelector(
    (state) => state.purchaseOrders.created
  );
  const purchaseOrderUpdated = useSelector(
    (state) => state.purchaseOrders.updated
  );
  const [open, setOpen] = useState(false);
  const [addPurchaseOrder, setAddPurchaseOrder] = useState(false);
  const [focusPurchaseOrder, setFocusPurchaseOrder] = useState({});
  const [orderDetailsTitle, setOrderDetailsTitle] = useState(NOT_SELECTED);

  useEffect(() => {
    if (purchaseOrderCreated || purchaseOrderUpdated) {
      dispatch(fetchPurchaseOrders({ token: user.token }));
      if (purchaseOrderCreated) {
        dispatch({ type: 'purchase-order/setCreated', value: false });
      }
      if (purchaseOrderUpdated) {
        dispatch({ type: 'purchase-order/setUpdated', value: false });
      }
    }
  }, [purchaseOrderCreated, purchaseOrderUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.token) {
      dispatch(fetchVendors({ token: user.token }));
      dispatch(fetchPurchaseOrders({ token: user.token }));
      dispatch(fetchCardEvents({ token: user.token }));
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddNewClick = () => {
    setOpen(true);
    setAddPurchaseOrder(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddPurchaseOrder(false);
  };

  const handleSave = (purchaseOrderId, payload) => {
    let args = {
      token: user?.token,
      payload: payload,
      purchaseOrderId: purchaseOrderId,
    };
    if (addPurchaseOrder) {
      dispatch(createPurchaseOrder(args));
    } else {
      dispatch(updatePurchaseOrder(args));
    }
  };

  const handleRowClick = (e) => {
    setFocusPurchaseOrder(e.row);
    setOpen(true);
    setOrderDetailsTitle(e.row.vendor_name + ' Order Details');
  };

  return (
    <div>
      <PurchaseOrderModal
        open={open}
        editable={addPurchaseOrder}
        handleClose={handleClose}
        handleSave={handleSave}
        order={focusPurchaseOrder}
        vendors={vendors}
      />

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h6'>Purchase Orders</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <PurchaseOrderTable
            user={user}
            rows={purchaseOrders}
            rowClickEvent={handleRowClick}
            onAddNewClick={onAddNewClick}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h6'>{orderDetailsTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.orderDetails}>
          <OrderItemsGallery
            user={user}
            hasOrder={orderDetailsTitle !== NOT_SELECTED}
            purchaseOrder={focusPurchaseOrder}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export { PurchaseOrderView };
