import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  address: {
    textAlign: 'left',
    margin: theme.spacing(0),
  },
  h6: {
    fontSize: '1rem',
  },
}));

const RecipientAddress = (props) => {
  const classes = useStyles();
  const { recipient } = props;
  if (!recipient?.event) {
    return null;
  }

  return (
    <div className={classes.address}>
      <Typography variant='h6' className={classes.h6}>
        <b>Address</b>
      </Typography>
      <Typography variant='body1'>{recipient.address_one}</Typography>
      {recipient?.address_two && (
        <Typography variant='body1'>{recipient.address_two}</Typography>
      )}
      <Typography variant='body1'>
        {recipient.city}, {recipient.state} {recipient.zip_code}
      </Typography>
    </div>
  );
};

export { RecipientAddress };
