import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteModal } from '../DeleteModal';
import { updateVendor } from '../../features/vendors/vendorSlice';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
  {
    field: 'website',
    headerName: 'Website',
    width: 150,
  },
  {
    field: 'email_address',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 200,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 200,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 150,
  },
  {
    field: 'zipcode',
    headerName: 'Zipcode',
    width: 150,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    width: 200,
  },
];

const useStyles = makeStyles((theme) => ({
  remove: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#014062',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const VendorTable = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [selections, setSelections] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [open, setOpen] = useState(false);

  const handleModalClose = () => {
    setOpen(false);
  };

  const onClickCancel = () => {
    setUsersToRemove([]);
    setOpen(false);
  };

  const onConfirmRemove = () => {
    usersToRemove.forEach((x) => {
      dispatch(
        updateVendor({
          token: props.user?.token,
          vendorId: x,
          payload: {
            active: false,
          },
        })
      );
    });
    setOpen(false);
  };

  const checkSearchText = (input) => {
    if (searchText) {
      return input.toLowerCase().includes(searchText);
    }
    return true;
  };

  const parseRows = (rows) => {
    return rows.filter((r) => checkSearchText(r.name));
  };

  const onRemoveClick = () => {
    setUsersToRemove(selections);
    setOpen(true);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DeleteModal
        open={open}
        handleClose={handleModalClose}
        onClickCancel={onClickCancel}
        onClickRemove={onConfirmRemove}
        modalClassName={classes.modal}
        message='Remove these vendors?'
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <TextField
            placeholder='Search by Name'
            variant='outlined'
            style={{ margin: 6 }}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={selections.length === 0}
            onClick={onRemoveClick}
            className={classes.remove}
          >
            Delete Vendor?
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={props.onAddNewClick}
          >
            New Vendor
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        rows={parseRows(props.rows)}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(e) => setSelections(e)}
        onRowClick={props.onRowClick}
      />
    </div>
  );
};

export { VendorTable };
