import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export default function taskTableReducer(state = initialState, action) {
  switch (action.type) {
    case 'tasktable/fetch/fulfilled': {
      return action.payload;
    }
    default:
      return state;
  }
}

export const fetchTaskTable = createAsyncThunk(
  'tasktable/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const query = args?.admin_user_id
      ? `?admin_user_id=${args.admin_user_id}&results_per_page=500`
      : '?results_per_page=500';
    const response = await client.get(
      `/api/v0/tasks/${args.status}/${args.page}${query}`,
      { headers: headers }
    );
    return response.data;
  }
);
