import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  order: {},
  orderItems: [],
  created: false,
  updated: false,
  deleted: false,
  message: {},
};

export default function orderItemsReducer(state = initialState, action) {
  switch (action.type) {
    case 'order-item/set': {
      return {
        ...state,
        order: action.value,
      };
    }
    case 'order-item/setCreated': {
      return {
        ...state,
        created: action.value,
      };
    }
    case 'order-item/setUpdated': {
      return {
        ...state,
        updated: action.value,
      };
    }
    case 'order-item/setDeleted': {
      return {
        ...state,
        deleted: action.value,
      };
    }
    case 'order-item/setOrderItems': {
      return {
        ...state,
        orderItems: action.value,
      };
    }
    case 'order-item/fetch/fulfilled': {
      return {
        ...state,
        orderItems: action.payload,
      };
    }
    case 'order-item/update/fulfilled': {
      return {
        ...state,
        updated: action.payload?.message === 'success',
        message: {
          text: 'Order Item Updated!',
          severity: 'success',
        },
      };
    }
    case 'order-item/create/fulfilled': {
      return {
        ...state,
        created: action.payload?.message === 'success',
      };
    }
    case 'order-item/delete/fulfilled': {
      return {
        ...state,
        deleted: action.payload?.message === 'success',
      };
    }
    case 'order-item/create/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Failed to Update Order Item!',
          severity: 'error',
        },
      };
    }
    case 'order-item/delete/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Failed to Delete Order Item!',
          severity: 'error',
        },
      };
    }
    default:
      return state;
  }
}

export const fetchOrderItems = createAsyncThunk(
  'order-item/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(
      `/api/v0/order_items/${args.purchaseOrderId}`,
      {
        headers: headers,
      }
    );
    return response.data;
  }
);

export const updateOrderItem = createAsyncThunk(
  'order-item/update',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/order_item/${args.orderItemId}`,
      args.payload,
      {
        headers: headers,
      }
    );
    return response;
  }
);

export const createOrderItem = createAsyncThunk(
  'order-item/create',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/create_order_item/${args.purchaseOrderId}`,
      args.payload,
      {
        headers: headers,
      }
    );
    return response;
  }
);

export const deleteOrderItem = createAsyncThunk(
  'order-item/delete',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.delete(
      `/api/v0/order_item/${args.orderItemId}`,
      {
        headers: headers,
      }
    );
    return response;
  }
);
