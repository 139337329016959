import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 200,
    padding: theme.spacing(1),
    border: '2px solid #d5d5d5',
    borderRadius: 8,
  },
  selectCard: {
    height: 120,
    width: 120,
    margin: theme.spacing(0),
  },
  titleBar: {
    background: 'rgba(0,0,0,0)',
  },
  image: {
    border: '1px solid #d5d5d5',
  },
  imageList: {
    width: '100%',
    height: 240,
    margin: theme.spacing(1),
  },
  icon: {
    margin: theme.spacing(1),
    color: '#cd0d0d',
  },
  title: {
    padding: theme.spacing(1),
  },
}));

const SelectedCard = (props) => {
  const { classes, cart, onCancelClick, rank } = props;
  const onClick = () => {
    onCancelClick(rank);
  };
  if (cart.length > rank) {
    return (
      <div>
        <ImageListItemBar
          className={classes.titleBar}
          position='top'
          actionIcon={
            <IconButton onClick={onClick} className={classes.icon}>
              <RemoveShoppingCartIcon />
            </IconButton>
          }
          actionPosition='right'
        />

        <img alt='' style={{ height: 120, width: 120 }} src={cart[rank]?.url} />
      </div>
    );
  }
  return (
    <Paper className={classes.selectCard}>
      <div style={{ textAlign: 'center', padding: 2 }}>
        <Typography variant='body1'>Select a Card</Typography>
      </div>
    </Paper>
  );
};

const CardCart = (props) => {
  const classes = useStyles();
  const { onCancelClick, cart } = props;
  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant='body1'>
        Card Selections
      </Typography>
      <ImageList rowHeight='auto' cols={3} className={classes.imageList}>
        {[0, 1, 2].map((index) => (
          <ImageListItem key={`selected_card_${index}`}>
            <SelectedCard
              cart={cart}
              rank={index}
              classes={classes}
              onCancelClick={onCancelClick}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export { CardCart };
