import { initializeApp } from 'firebase/app';

export function initializeFirebase() {
  return initializeApp({
    apiKey: 'AIzaSyDuZkfKoanCmFaqqFemUhHDYHKLoCidia0',
    authDomain: 'significard-admin-portal.firebaseapp.com',
    projectId: 'significard-admin-portal',
    storageBucket: 'significard-admin-portal.appspot.com',
    messagingSenderId: '290821785727',
    appId: '1:290821785727:web:7ccf3565d3050d0275d74a',
  });
}
