import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import RecipientForm from './RecipientForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '50%',
    height: '70%',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    margin: theme.spacing(6),
    left: '15%',
    overflow: 'scroll',
  },
}));

const RecipientModal = ({
  open,
  editable,
  handleClose,
  recipient,
  recipientId,
  cardId,
  isCard,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.root}>
        <RecipientForm
          recipientId={recipientId}
          recipient={recipient}
          cardId={cardId}
          isCard={isCard}
          editable={editable}
        />
      </div>
    </Modal>
  );
};

export default RecipientModal;
