const logoPath =
  'https://img1.wsimg.com/isteam/ip/e96839b4-c4ac-4069-b4ae-e819888a07b9/850F29FD-6751-4DB9-A3A6-5A0E19A41583.png/:/rs=w:400,h:400,cg:true,m/cr=w:400,h:400/qt=q:95';

const paths = {
  login: '/login',
  tasks: '/tasks',
  customers: '/customers',
  profile: '/profile',
  recommendations: '/recommendations',
  orders: '/orders',
  logo: logoPath,
};

export default paths;
