import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';

const TAG_LENGTH = 2;

const EVENT_FORMATTING = {
  birthday: {
    label: 'Birthday',
    emoji: 'partying-face.png',
  },
  anniversary: {
    label: 'Anniversary',
    emoji: 'bouquet.png',
  },
  'valentines day': {
    label: 'Valentines Day',
    emoji: 'red-heart.png',
  },
  'mothers day': {
    label: 'Mothers Day',
    emoji: 'bouquet.png',
  },
  'fathers day': {
    label: 'Fathers Day',
    emoji: 'red-heart.png',
  },
  christmas: {
    label: 'Christmas',
    emoji: 'santa-claus.png',
  },
  'miss you': {
    label: 'Miss You',
    emoji: 'hugging-face.png',
  },
  'thinking of you': {
    label: 'Thinking of You',
    emoji: 'smiling-face-with-hearts.png',
  },
  'thank you': {
    label: 'Thank You',
    emoji: 'partying-face.png',
  },
  sympathy: {
    label: 'Sympathy',
    emoji: 'tulip.png',
  },
  congratualations: {
    label: 'Congratulations',
    emoji: 'clapping-hands.png',
  },
  encouragement: {
    label: 'Encouragement',
    emoji: 'raising-hands.png',
  },
  graduation: {
    label: 'Graduation',
    emoji: 'graduation-cap.png',
  },
  'new year': {
    label: 'New Year',
    emoji: 'clinking-glasses.png',
  },
  'baby shower': {
    label: 'Baby Shower',
    emoji: 'baby-bottle.png',
  },
  hannukah: {
    label: 'Hannukah',
    emoji: 'star-of-david.png',
  },
  other: {
    label: 'Other',
    emoji: 'hugging-face.png',
  },
};

const getEventImage = (event) => {
  if (!(event in EVENT_FORMATTING)) {
    return {
      label: 'Other',
      emoji: 'hugging-face.png',
    };
  }
  return EVENT_FORMATTING[event];
};

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 200,
    width: 175,
    margin: 'auto',
  },
  image: {
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  arrival: {
    textAlign: 'center',
  },
  divider: {
    width: '50%',
  },
  text: {
    fontSize: '.8rem',
  },
  popper: {
    zIndex: 3000,
  },
  popperButton: {
    textTransform: 'none',
  },
  tagPaper: {
    border: '1px solid',
    borderRadius: 12,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: 'auto',
    height: 'auto',
    textAlign: 'center',
  },
}));

const EventImage = (props) => {
  const data = getEventImage(props.event);
  return (
    <div className={props.className}>
      <img src={`media/emojis/${data.emoji}`} alt={`${data.label}`} />
      <Typography variant='h6'>{data.label}</Typography>
    </div>
  );
};

const RecipientEventCard = (props) => {
  const classes = useStyles();
  const { recipient } = props;
  const [tagAnchorEl, setTagAnchorEl] = useState(null);

  const handleTagClick = (event) => {
    setTagAnchorEl(tagAnchorEl ? null : event.currentTarget);
  };

  const tagOpen = Boolean(tagAnchorEl);
  const tagPopperId = tagOpen ? 'simple-popper' : undefined;

  const formatTags = (tags, maxLength) => {
    if (tags?.length > 0) {
      if (tags.length <= maxLength) {
        return tags.join(', ');
      }
      return tags.slice(0, maxLength).join(', ');
    }
    return '';
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <EventImage
              event={recipient?.event || 'other'}
              className={classes.image}
            />
          </Grid>
          <Divider variant='middle' className={classes.divider} />
          <Grid item className={classes.arrival}>
            <Typography className={classes.text}>
              <b>Est. Arrival Date:</b>
            </Typography>
            <Typography className={classes.text} variant='subtitle1'>
              {recipient?.card_arrival || ''}
            </Typography>
          </Grid>
          <Grid item></Grid>
          <Typography className={classes.text}>
            <b>Tags:</b>
          </Typography>

          {(recipient?.tags || []).length > TAG_LENGTH ? (
            <Typography className={classes.text} variant='subtitle1'>
              <Button
                endIcon={<MoreHorizIcon />}
                onClick={handleTagClick}
                className={classes.popperButton}
              >
                {formatTags(recipient?.tags || [], TAG_LENGTH)}
              </Button>
              <Popper
                className={classes.popper}
                id={tagPopperId}
                open={tagOpen}
                anchorEl={tagAnchorEl}
              >
                <div className={classes.tagPaper}>
                  {formatTags(recipient?.tags || [], 300)}
                </div>
              </Popper>
            </Typography>
          ) : (
            <Typography className={classes.text} variant='subtitle1'>
              {formatTags(recipient?.tags || [], TAG_LENGTH)}
            </Typography>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export { RecipientEventCard };
