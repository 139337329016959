import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ImageRow from '../components/cards/CardImageRow.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SenderModal from '../components/tasks/SenderModal';
import RecipientModal from '../components/tasks/RecipientModal';
import { AdminUserInfo } from '../components/admin/AdminUserInfo';
import { CardModal } from '../components/cards/CardModal';
import { fetchRecommendationsById } from '../features/recommendations/recommendationsSlice';
import { fetchRecipientById } from '../features/recipient/recipientSlice';
import { fetchSenderById } from '../features/sender/senderSlice';
import { RecipientInfo } from '../components/recipients/RecipientInfo';
import { SnackbarAlert } from '../components/SnackbarAlert';
import { CardSelectionContainer } from '../components/cards/CardSelectionContainer';
import { fetchCardCatalog } from '../features/card-catalog/cardCatalogSlice';
import {
  fetchCard,
  fetchCardTags,
} from '../features/select-card/selectCardSlice';

const DEFAULT_PAGE_SIZE = 40;
const EVENTS = {
  'valentines day': 1,
  grandomther: 2,
  easter: 3,
  quarantine: 4,
  sorry: 5,
  'fathers day': 6,
  'mothers day': 7,
  wedding: 8,
  graduation: 9,
  birthday: 10,
  mood: 11,
  thanksgiving: 12,
  anniversary: 13,
  'new year': 14,
  christmas: 15,
  baby: 16,
  'thank you': 17,
  'thinking of you': 20,
  congratulations: 21,
};

const MAX_CARDS = 3;

const useStyles = makeStyles((theme) => ({
  imageList: {
    width: '100%',
  },
  recommendations: {
    display: 'block',
    margin: theme.spacing(2),
  },
  senderInfo: {
    margin: theme.spacing(1, 2),
    padding: theme.spacing(1),
    backgroundColor: '#e7e7e7',
  },
}));

const SenderInfo = (props) => {
  const { name, mailTo, email, signature, className } = props;

  if (!name) {
    return null;
  }

  return (
    <Paper className={className}>
      <Typography gutterBottom variant='body1'>
        <b>From:</b> {name}
      </Typography>
      <Typography gutterBottom variant='body1'>
        <b>Mail To:</b> {mailTo}
      </Typography>
      <Typography gutterBottom variant='body1'>
        <b>Email:</b> {email}
      </Typography>
      <Typography gutterBottom variant='body1'>
        <b>Signature:</b> {signature}
      </Typography>
    </Paper>
  );
};

const TabPanel = (props) => {
  const { value, index, cardProps } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <ImageRow {...cardProps} />}
    </div>
  );
};

const tabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const getQueryString = (eventTypeId) => {
  let params = {
    event_type_id: eventTypeId,
    results_per_page: DEFAULT_PAGE_SIZE,
  };
  let searchParams = new URLSearchParams(params);
  return searchParams.toString();
};

export function RecommendationView() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cards = useSelector((state) => state.cardCatalog.cards);

  const assigned = useSelector((state) => state.task.assigned);
  const cart = useSelector((state) => state.selectCard.cart);
  const recipient = useSelector((state) => state.recipient.recipient);
  const sender = useSelector((state) => state.sender.sender);
  const recommendations = useSelector((state) => state.recommendations);
  const task = useSelector((state) => state.task.task);
  const recipientUpdated = useSelector((state) => state.recipient.status);
  const senderUpdated = useSelector((state) => state.sender.status);
  const recipientMessage = useSelector((state) => state.recipient.messsage);
  const senderMessage = useSelector((state) => state.sender.message);
  const taskUpdated = useSelector((state) => state.task.updated);
  const taskMessage = useSelector((state) => state.task.message);
  const taskUser = useSelector((state) => state.task.user);
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [openRecipient, setOpenRecipient] = useState(false);
  const [openSender, setOpenSender] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);

  const fetchCatalogPage = (page, event) => {
    let args = {
      page: page,
      token: user.token,
      queryString: getQueryString(EVENTS[event]),
    };
    dispatch(fetchCardCatalog(args));
    setPage(page);
  };

  const handleOpen = (item) => {
    let args = {
      token: user?.token,
      cardId: item.id,
    };
    dispatch(fetchCard(args));
    dispatch(fetchCardTags(args));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectCardAction = (selectedCard) => {
    dispatch({ type: 'selectCard/set', value: selectedCard });
    if (cart.length < MAX_CARDS) {
      dispatch({ type: 'selectCard/addToCart', value: selectedCard });
    }
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const onClickPrevious = () => {
    if (page > 1 && tabValue === 1) {
      fetchCatalogPage(page - 1, task.event);
    }
  };

  const onClickNext = () => {
    if (tabValue === 1) {
      fetchCatalogPage(page + 1, task.event);
    }
  };
  useEffect(() => {
    if (recipient?.event) {
      let args = {
        token: user?.token,
        body: {
          event: recipient.event,
          tags: recipient.tags || [],
        },
      };
      dispatch(fetchRecommendationsById(args));
    }
  }, [recipient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (task?.recipient_id || task?.card_task_id) {
      let options = {};
      if (task?.is_card_task) {
        options = {
          recipientId: task.recipient_id,
          cardId: task.card_task_id,
        };
      } else {
        options = {
          recipientId: task?.recipient_id,
        };
      }
      let args = {
        ...options,
        token: user?.token,
      };
      dispatch(fetchRecipientById(args));
    }
    if (task?.sender_id) {
      let args = {
        token: user?.token,
        senderId: task?.sender_id,
      };
      dispatch(fetchSenderById(args));
    }
    if (task?.card_id) {
      dispatch(fetchCard({ cardId: task.card_id, token: user?.token }));
    }
    if (task?.event) fetchCatalogPage(1, task.event);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (taskUpdated) {
      setSnackbarOpen(true);
      dispatch({ type: 'task/setUpdated', value: false });
    }
  }, [taskUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (recipientUpdated !== 'pending') {
      setSnackbarOpen(true);
      dispatch({ type: 'recipient/setPending' });
    }
  }, [recipientUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (senderUpdated !== 'pending') {
      setSnackbarOpen(true);
      dispatch({ type: 'sender/setPending' });
    }
  }, [senderUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const getImageProps = () => {
    return {
      onClick: handleOpen,
      iconAction: selectCardAction,
      prefix: tabValue === 0 ? 'recs' : 'all',
      data: (tabValue === 0 ? recommendations : cards) || [],
    };
  };

  const handleSenderInfoClick = () => {
    setOpenSender(true);
  };

  const handleRecipientInfoClick = () => {
    setOpenRecipient(true);
  };

  const handleCloseSender = () => {
    setOpenSender(false);
  };

  const handleCloseRecipient = () => {
    setOpenRecipient(false);
  };

  return (
    <div>
      <CardModal
        open={open}
        handleClose={handleClose}
        editable={false}
        events={[]}
        themes={[]}
      />
      <SenderModal
        open={openSender}
        handleClose={handleCloseSender}
        editable={user?.user_group_id === 1}
        sender={sender}
        senderId={task?.sender_id}
      />
      <RecipientModal
        open={openRecipient}
        handleClose={handleCloseRecipient}
        editable={user?.user_group_id === 1}
        recipientId={task?.recipient_id}
        recipient={recipient}
        cardId={task?.card_task_id}
        isCard={task?.is_card_task}
      />
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <AdminUserInfo
            assigned={assigned}
            adminUserId={user?.id}
            taskId={task?.id}
            taskAdminUserId={taskUser?.id}
            taskAdminUser={taskUser?.name}
            token={user?.token}
          />
          <div onClick={handleSenderInfoClick}>
            <SenderInfo
              name={sender?.username}
              signature={recipient?.signature}
              mailTo={recipient?.mail_to}
              email={sender?.email}
              className={classes.senderInfo}
            />
          </div>
          <div onClick={handleRecipientInfoClick}>
            <RecipientInfo recipient={recipient} orientation='column' />
          </div>
        </Grid>
        <Divider orientiation='vertical' flexItem style={{ width: 1 }} />
        <Grid
          item
          container
          direction='column'
          xs={8}
          className={classes.recommendations}
        >
          <Grid item style={{ float: 'right' }}>
            <IconButton
              disabled={page === 1 || tabValue === 0}
              onClick={onClickPrevious}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              disabled={cards?.length < DEFAULT_PAGE_SIZE || tabValue === 0}
              onClick={onClickNext}
            >
              <NavigateNextIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Box className={classes.tab}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label='Recommendations' {...tabProps(0)} />
                <Tab label='All Cards' {...tabProps(1)} />
              </Tabs>
            </Box>
            {['Recommendations', 'All Cards'].map((label, index) => (
              <TabPanel
                key={`tab_${index}`}
                value={tabValue}
                index={index}
                cardProps={getImageProps()}
              >
                {label}
              </TabPanel>
            ))}
          </Grid>
          <CardSelectionContainer
            assigned={assigned}
            adminUserId={user?.id}
            cart={cart}
            task={task}
            token={user?.token}
            message={recipient?.message || task?.message}
          />
        </Grid>
      </Grid>
      <SnackbarAlert
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={
          taskMessage?.text || recipientMessage?.text || senderMessage?.text
        }
        severity={taskMessage?.severity}
      />
    </div>
  );
}
