import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { CardImageList } from './CardImageList';
import { CardModal } from './CardModal';
import { EventThemeSelection } from '../EventThemeSelection';
import {
  fetchCardCatalog,
  fetchCardThemes,
  fetchCardEvents,
} from '../../features/card-catalog/cardCatalogSlice';
import { fetchCard } from '../../features/select-card/selectCardSlice';
import { fetchTags } from '../../features/tags/tagSlice';
const DEFAULT_PAGE_SIZE = 16;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    width: '95%',
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  accordion: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    boxShadow: 'none',
  },
  cardFilters: {
    display: 'flex',
    margin: theme.spacing(2, 1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  themeButton: {
    color: '#014062',
    '&:hover': {
      color: '#0771ab',
    },
  },
  reassign: {
    color: '#e7e7e7',
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  popper: {
    zIndex: 3000,
  },
}));

const getQueryString = (eventTypeId, themeId) => {
  let params = {
    event_type_id: eventTypeId,
  };
  if (themeId) {
    params.theme_id = themeId;
  }
  let searchParams = new URLSearchParams(params);
  return searchParams.toString();
};

const CardCatalog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const cards = useSelector((state) => state.cardCatalog.cards);
  const events = useSelector((state) => state.cardCatalog.events);
  const eventTypeId = useSelector((state) => state.cardCatalog.eventTypeId);
  const themeId = useSelector((state) => state.cardCatalog.themeId);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [refreshCatalog, setRefreshCatalog] = useState(false);

  const fetchCatalogPage = (page, eventTypeId, themeId) => {
    let args = {
      page: page,
      token: user.token,
      queryString: getQueryString(eventTypeId, themeId),
    };
    dispatch(fetchCardCatalog(args));
    setPage(page);
  };

  useEffect(() => {
    if (user?.token) {
      fetchCatalogPage(page, eventTypeId, themeId);
      dispatch(fetchCardThemes({ eventType: eventTypeId, token: user.token }));
      dispatch(fetchCardEvents({ token: user.token }));
      dispatch(fetchTags({ token: user.token }));
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEventSelect = (e) => {
    let eventId = e.target.value;
    dispatch({
      type: 'card-catalog/setEventId',
      value: eventId,
    });
    if (!open) {
      fetchCatalogPage(1, eventId, null);
    } else {
      setRefreshCatalog(true);
    }

    dispatch(fetchCardThemes({ eventType: eventId, token: user?.token }));
    dispatch({ type: 'card-catalog/setThemeId', value: null });
  };

  const onClickPrevious = () => {
    if (page > 1) {
      fetchCatalogPage(page - 1, eventTypeId, themeId);
    }
  };

  const onClickNext = () => {
    fetchCatalogPage(page + 1, eventTypeId, themeId);
  };

  const onClickImage = (item) => {
    let args = {
      token: user?.token,
      cardId: item.id,
    };
    dispatch(fetchCard(args));
    setOpen(true);
  };

  const onAddNewClick = () => {
    dispatch({ type: 'selectCard/set', value: {} });
    dispatch({ type: 'selectCard/setTags', value: [] });
    setRefreshCatalog(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: 'selectCard/set', value: {} });
    dispatch({ type: 'selectCard/setTags', value: [] });
    if (refreshCatalog) {
      fetchCatalogPage(1, eventTypeId, themeId);
    }
  };

  return (
    <Box className={classes.root}>
      <CardModal
        open={open}
        handleClose={handleClose}
        editable={true}
        events={events}
        eventTypeId={eventTypeId}
        themeId={themeId}
        onEventSelect={onEventSelect}
        enableTokenLookup={false}
      />
      <Grid container direction='column'>
        <Grid item>
          <Grid
            container
            direction='row'
            spacing={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <EventThemeSelection
                events={events}
                eventTypeId={eventTypeId}
                onEventSelect={onEventSelect}
                disabled={false}
              />
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={onAddNewClick}
              >
                New Card
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Paper className={classes.paper}>
          <CardImageList
            cards={cards}
            disabledPrevious={page === 1}
            disabledNext={cards.length < DEFAULT_PAGE_SIZE}
            clickPrevious={onClickPrevious}
            clickNext={onClickNext}
            onClickImage={onClickImage}
          />
        </Paper>
      </Grid>
    </Box>
  );
};

export { CardCatalog };
