import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { EditBar } from '../EditBar';
import {
  updateRecipientById,
  updateRecipientCardById,
} from '../../features/recipient/recipientSlice';

const FIELDS = [
  { field: 'recipient', editable: true, label: 'Recipient', card: false },
  {
    field: 'recipient_name',
    editable: true,
    label: 'Recipient Name',
    card: true,
  },
  {
    field: 'recipient_other',
    editable: true,
    label: 'Recipient Other',
    card: false,
  },
  { field: 'age', editable: true, label: 'Age', card: false },
  { field: 'event', editable: true, label: 'Event', card: true },
  { field: 'event_other', editable: true, label: 'Event Other', card: true },
  { field: 'card_arrival', editable: true, label: 'Card Arrival', card: true },
  { field: 'other', editable: true, label: 'Notes', card: false },
  { field: 'name', editable: true, label: 'Address Name', card: false },
  { field: 'mail_to', editable: true, label: 'MailTo' },
  {
    field: 'address_one',
    editable: true,
    label: 'Address Line 1',
    card: false,
  },
  {
    field: 'address_two',
    editable: true,
    label: 'Address Line 2',
    card: false,
  },
  { field: 'city', editable: true, label: 'City', card: false },
  { field: 'state', editable: true, label: 'State', card: false },
  { field: 'zip_code', editable: true, label: 'zip_code', card: false },
  { field: 'description', editable: true, label: 'Description', card: false },
  { field: 'nickname', editable: true, label: 'Nickname', card: true },
  { field: 'message', editable: true, label: 'Message', card: true },
  { field: 'signature', editable: true, label: 'signature', card: true },
];

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(1),
  },
  long: {
    width: '75ch',
  },
  medium: {
    width: '25ch',
  },
  short: {
    width: '16ch',
  },
}));

const RecipientForm = ({
  recipientId,
  cardId,
  recipient,
  editable,
  isCard,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const status = useSelector((state) => state.recipient.status);
  const [values, setValues] = useState({});
  const [updatedFields, setUpdatedFields] = useState([]);
  const [updatedCardFields, setUpdatedCardFields] = useState([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (recipient) {
      let data = FIELDS.reduce(
        (a, v) => ({ ...a, [v.field]: recipient[v.field] }),
        {}
      );
      setValues(data);
    }
  }, [recipient]);

  useEffect(() => {
    if (status === 'succeeded') {
      let data = FIELDS.reduce(
        (a, v) => ({ ...a, [v.field]: recipient[v.field] }),
        {}
      );
      setValues(data);
      setUpdatedFields([]);
      setUpdatedCardFields([]);
    }
  }, [status]);

  const onFieldEdit = (field, onCard) => (e) => {
    const updates = {
      ...values,
      [field]: e.target.value,
    };
    setValues(updates);
    if (isCard && onCard) {
      setUpdatedCardFields([...updatedCardFields, field]);
    } else {
      setUpdatedFields([...updatedFields, field]);
    }
  };

  const onClickSave = () => {
    if (user?.token) {
      if (updatedFields.length > 0) {
        let args = {
          token: user.token,
          body: updatedFields.reduce((a, v) => ({ ...a, [v]: values[v] }), {}),
          recipientId: recipientId,
        };
        dispatch(updateRecipientById(args));
      }
      if (updatedCardFields.length > 0) {
        let args = {
          token: user.token,
          body: updatedCardFields.reduce(
            (a, v) => ({ ...a, [v]: values[v] }),
            {}
          ),
          recipientId: recipientId,
          cardId: cardId,
        };
        dispatch(updateRecipientCardById(args));
      }
    }
  };

  const onClickCancel = () => {
    setEditMode(false);
  };

  return (
    <Box>
      <EditBar
        editMode={editMode}
        saveDisabled={false}
        setEditMode={setEditMode}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
      <Grid container direction='column' spacing={1} alignItems='flex-start'>
        {FIELDS.map((item, index) => (
          <Grid item key={`recipient-form-${index}`}>
            <TextField
              className={classes.long}
              required
              size='small'
              id={`recipient-form-${item.field}`}
              label={item.label}
              variant='outlined'
              value={values[item.field]}
              disabled={!(editMode && item.editable && editable)}
              onChange={onFieldEdit(item.field, item.card)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RecipientForm;
