import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    flexGrow: 1,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const filter = createFilterOptions();

const TagSelect = (props) => {
  const classes = useStyles();
  const tags = useSelector((state) => state.tags.tags);
  const { selectedTags, disabled, onChange } = props;

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      let matches = filtered.filter((x) => x.name === params.inputValue);
      if (matches.length === 0) {
        filtered.push({
          id: -1,
          label: `Add ${params.inputValue}`,
          name: params.inputValue,
        });
      }
    }
    return filtered;
  };

  const renderInput = (params) => {
    return <TextField {...params} label='Tags' variant='outlined' />;
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        disabled={disabled}
        value={selectedTags}
        multiple
        onChange={onChange}
        filterOptions={filterOptions}
        limitTags={3}
        id='multiple-limit-tags'
        options={tags}
        renderOption={(option) => option?.label || option.name}
        getOptionLabel={(option) => option.name}
        renderInput={renderInput}
        freeSolo
      />
    </div>
  );
};

export { TagSelect };
