import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { RecipientAddress } from './RecipientAddress';
import { RecipientEventCard } from './RecipientEventCard';
import { RecipientCardPreferences } from './RecipientCardPreferences';

const WIDTH_COL = 240;
const WIDTH_ROW = 320;
const MAX_NOTE_CHAR = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  h6: {
    fontSize: '1rem',
  },
  info: {
    margin: theme.spacing(0),
    width: 140,
  },
  paper: {
    backgroundColor: '#e7e7e7',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  otherCol: {
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: 240,
    margin: theme.spacing(1),
  },
  otherRow: {
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: 320,
    margin: theme.spacing(1),
  },
  popper: {
    zIndex: 3000,
  },
  notesButton: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    textTransform: 'none',
  },
  notesPaper: {
    border: '1px solid',
    borderRadius: 12,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: 320,
    height: 'auto',
    textAlign: 'center',
  },
}));

const RecipientInfo = (props) => {
  const classes = useStyles();
  const { recipient, orientation } = props;
  const [notesAnchorEl, setNotesAnchorEl] = useState(null);

  const handleNotesClick = (event) => {
    setNotesAnchorEl(notesAnchorEl ? null : event.currentTarget);
  };

  const notesOpen = Boolean(notesAnchorEl);
  const notesPopperId = notesOpen ? 'simple-popper' : undefined;

  const formatNotes = (notes, maxCharacters) => {
    if (notes?.length > maxCharacters) {
      return (
        <div>
          <Button
            endIcon={<MoreHorizIcon />}
            onClick={handleNotesClick}
            className={classes.notesButton}
          >
            <Typography variant='h6' className={classes.h6}>
              <b>Notes:</b>
            </Typography>
          </Button>
          <Popper
            className={classes.popper}
            id={notesPopperId}
            open={notesOpen}
            anchorEl={notesAnchorEl}
          >
            <Paper className={classes.notesPaper}>
              <Typography variant='body1'>{notes}</Typography>
            </Paper>
          </Popper>
          <Typography>{`${notes.slice(0, maxCharacters)}...`}</Typography>
        </div>
      );
    }
    return (
      <div>
        <Typography variant='h6' className={classes.h6}>
          <b>Notes:</b>
        </Typography>
        <Typography variant='body1'>{notes}</Typography>{' '}
      </div>
    );
  };
  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container direction={orientation} spacing={2}>
          <Grid item>
            <RecipientEventCard recipient={recipient} />
          </Grid>
          <Grid item className={classes.info}>
            <Typography variant='h6' className={classes.h6}>
              <b>Name:</b>
            </Typography>
            <Typography variant='body1'>{recipient.recipient_name}</Typography>
            <Typography variant='h6' className={classes.h6}>
              <b>Age:</b>
            </Typography>
            <Typography variant='body1'>{recipient.age}</Typography>
            <Typography variant='h6' className={classes.h6}>
              <b>Personality:</b>
            </Typography>
            <Typography variant='body1' style={{ overflow: 'wrap' }}>
              {recipient.description}
            </Typography>
          </Grid>
          <Grid item>
            <RecipientAddress recipient={recipient} />
          </Grid>
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <RecipientCardPreferences
                  width={orientation === 'row' ? WIDTH_ROW : WIDTH_COL}
                  rankings={recipient?.rank || []}
                />
              </Grid>
              <Grid
                item
                className={
                  orientation === 'row' ? classes.otherRow : classes.otherCol
                }
              >
                {formatNotes(recipient?.other, MAX_NOTE_CHAR)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export { RecipientInfo };
