import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  accordion: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    boxShadow: 'none',
  },
  newUser: {
    display: 'flex',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '22ch',
    },
  },
  button: {
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  reassign: {
    color: '#e7e7e7',
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const USER_GROUPS = {
  admin: 1,
  employee: 2,
};

const AddAdminUserAccordian = (props) => {
  const classes = useStyles();
  const { userCreated, handleClickSave, handleClearForm } = props;
  const [email, setEmail] = useState('Email');
  const [lastName, setLastName] = useState('Last Name');
  const [firstName, setFirstName] = useState('First Name');
  const [userGroup, setUserGroup] = useState(USER_GROUPS.employee);

  const clearForm = () => {
    setEmail('Email');
    setLastName('Last Name');
    setFirstName('First Name');
    setUserGroup(USER_GROUPS.employee);
    handleClearForm();
  };

  const clickSave = () => {
    // dispatch save action
    let payload = {
      email_address: email,
      first_name: firstName,
      last_name: lastName,
      user_group_id: userGroup,
    };
    handleClickSave(payload);
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<AddIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' className={classes.heading}>
          Add a User
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction='row' spacing={1} alignItems='center'>
          <Grid item xs={8}>
            <form noValidate className={classes.newUser} autoComplete='off'>
              <FormControl>
                <InputLabel>User Group</InputLabel>
                <Select
                  style={{ marginTop: 24 }}
                  value={userGroup}
                  onChange={(e) => setUserGroup(e.target.value)}
                >
                  <MenuItem value={USER_GROUPS.admin}>Admin</MenuItem>
                  <MenuItem value={USER_GROUPS.employee}>Employee</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id='standard-required'
                label='Required'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                required
                id='standard-required'
                label='Required'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                required
                id='standard-required'
                label='Required'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </form>
          </Grid>
          <Grid item>
            <Button
              size='large'
              variant='contained'
              color='primary'
              onClick={clickSave}
              disabled={userCreated}
              className={classes.button}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              size='large'
              variant='contained'
              color='default'
              onClick={clearForm}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export { AddAdminUserAccordian };
