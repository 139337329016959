import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { AdminUserTable } from './AdminUserTable';
import { AddAdminUserAccordian } from './AddAdminUserAccordian';
import {
  fetchAdminUsers,
  createAdminUser,
} from '../../features/admin-users/adminUserSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    width: '95%',
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    height: 600,
  },
}));

const UsersAdminView = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const adminUsers = useSelector((state) => state.adminUsers.users);
  const userCreated = useSelector((state) => state.adminUsers.created);
  const userUpdated = useSelector((state) => state.adminUsers.updated);

  useEffect(() => {
    if (user?.token || userCreated || userUpdated) {
      dispatch(fetchAdminUsers({ page: 1, token: user.token }));
      if (userCreated) {
        dispatch({ type: 'admin-users/setCreated', value: false });
      }
      if (userUpdated) {
        dispatch({ type: 'admin-users/setUpdated', value: false });
      }
    }
  }, [user, userCreated, userUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearForm = () => {
    dispatch({ type: 'admin-users/setCreated', value: false });
  };

  const clickSave = (payload) => {
    // dispatch save action
    let args = {
      payload: payload,
      token: user?.token,
    };
    dispatch(createAdminUser(args));
  };

  return (
    <Box className={classes.root}>
      <Grid container direction='column'>
        <Grid item>
          <AddAdminUserAccordian
            userCreated={userCreated}
            handleClickSave={clickSave}
            handleClearForm={clearForm}
          />
        </Grid>

        <Grid item>
          <Paper className={classes.paper}>
            <AdminUserTable user={user} rows={adminUsers} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export { UsersAdminView };
