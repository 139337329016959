import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export default function recommendationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'recommendations/fetch/fulfilled': {
      return action.payload;
    }
    default:
      return state;
  }
}

export const fetchRecommendationsById = createAsyncThunk(
  'recommendations/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(`/api/v0/recommendations`, args.body, {
      headers: headers,
    });
    return response.data;
  }
);
