import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  cards: [],
  themes: [],
  events: [],
  eventTypeId: 1,
  themeId: null,
};

const getThemeIdForAll = (themes) => {
  if(themes?.length === 0) {
    return null;
  }
  for( let t of themes) {
    if(t.name === 'All') {
      return t.id;
    }
  }
  return null;
}

export default function cardCatalogReducer(state = initialState, action) {
  switch (action.type) {
    case 'card-catalog/setEventId': {
      return {
        ...state,
        eventTypeId: action.value,
      };
    }
    case 'card-catalog/setThemeId': {
      return {
        ...state,
        themeId: action.value,
      };
    }
    case 'card-catalog/fetchThemes/fulfilled': {
      return {
        ...state,
        themes: action.payload,
        themeId: getThemeIdForAll(action.payload),
      };
    }
    case 'card-catalog/fetchEvents/fulfilled': {
      return {
        ...state,
        events: action.payload,
        eventTypeId: action.payload[0]?.id,
      };
    }
    case 'card-catalog/fetch/fulfilled': {
      return {
        ...state,
        cards: action.payload,
      };
    }
    case 'card-catalog/createTheme/fulfilled': {
      return {
        ...state,
        themes: action.payload.data,
        themeId: action.payload.id,
      };
    }
    case 'card-catalog/createEvent/fulfilled': {
      return {
        ...state,
        events: action.payload.data,
        eventTypeId: action.payload.id,
      };
    }
    default:
      return state;
  }
}

export const fetchCardCatalog = createAsyncThunk(
  'card-catalog/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const url = `/api/v0/card_catalog/${args.page}`.concat(
      args.queryString ? `?${args.queryString}` : ''
    );
    const response = await client.get(url, { headers: headers });
    return response.data;
  }
);

export const fetchCardThemes = createAsyncThunk(
  'card-catalog/fetchThemes',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/themes/${args.eventType}`, {
      headers: headers,
    });
    return response.data;
  }
);

export const fetchCardEvents = createAsyncThunk(
  'card-catalog/fetchEvents',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/event_types`, {
      headers: headers,
    });
    return response.data;
  }
);

export const createCardEvent = createAsyncThunk(
  'card-catalog/createEvent',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(`/api/v0/event_types`, args.body, {
      headers: headers,
    });
    return response;
  }
);

export const createCardTheme = createAsyncThunk(
  'card-catalog/createTheme',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/themes/${args.eventType}`,
      args.body,
      { headers: headers }
    );
    return response;
  }
);
