import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  sender: {},
  senderList: [],
  message: {},
  status: 'pending',
};

export default function senderReducer(state = initialState, action) {
  switch (action.type) {
    case 'sender/setPending': {
      return { ...state, status: 'pending', message: {} };
    }
    case 'sender/fetch/fulfilled': {
      return {
        ...state,
        sender: action.payload,
      };
    }
    case 'sender/fetchList/fulfilled': {
      return {
        ...state,
        senderList: action.payload,
      };
    }
    case 'sender/update/fulfilled': {
      return {
        ...state,
        sender: action.payload,
        status: 'succeeded',
        message: {
          text: 'Successfully Updated the Sender',
          severity: 'success',
        },
      };
    }
    case 'sender/update/rejected': {
      return {
        ...state,
        status: 'rejected',
        message: { text: 'Failed to Update the Sender', severity: 'error' },
      };
    }
    default:
      return state;
  }
}

export const fetchSenderById = createAsyncThunk(
  'sender/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const response = await client.get(`/api/v0/senders/${args.senderId}`, {
      headers: headers,
    });
    return response;
  }
);

export const fetchSenderList = createAsyncThunk(
  'sender/fetchList',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const response = await client.get(`/api/v0/active_senders/${args.page}`, {
      headers: headers,
    });
    return response.data;
  }
);

export const updateSenderById = createAsyncThunk(
  'sender/update',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const response = await client.post(
      `/api/v0/senders/update/${args.senderId}`,
      args.body,
      {
        headers: headers,
      }
    );
    return response;
  }
);
