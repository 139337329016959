import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SyncIcon from '@material-ui/icons/Sync';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { CardInventoryRow } from './CardInventoryRow';
import { EditBar } from '../EditBar';
import { EventThemeSelection } from '../EventThemeSelection';
import { TagSelect } from '../TagSelect';
import { SnackbarAlert } from '../SnackbarAlert';
import {
  fetchCardByToken,
  fetchCardTags,
  createCard,
  updateCard,
  updateCardTags,
} from '../../features/select-card/selectCardSlice';
import { fetchCardThemes } from '../../features/select-card/cardFormSlice';
import { fetchTags } from '../../features/tags/tagSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '75%',
    height: '75%',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    margin: theme.spacing(6),
    left: '5%',
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#ffffff',
    height: 460,
  },
  edit: {
    flexGrow: 1,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  description: {
    flexGrow: 1,
  },
  editPanel: {
    float: 'right',
  },
  upload: {
    margin: theme.spacing(24, 0),
  },
  inputBoxes: {
    width: '95%',
  },
  button: {
    color: '#fff',
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  chip: {
    backgroundColor: '#014062',
    borderRadius: 12,
    color: 'white',
    margin: theme.spacing(1),
  },
  eventRow: {
    width: '95%',
  },
  styleSelect: {
    width: 100,
  },
  unitsDisabled: {
    color: 'rgb(0, 0, 0, 0.38)',
  },
  unitsEnabled: {
    color: '#000',
  },
  tokenLookup: {
    width: 240,
    height: 32,
  },
  tokenText: {
    width: '15ch',
  },
}));

const ImageUploadContainer = (props) => {
  const { url, onChange, classes } = props;
  if (url !== '') {
    return (
      <img alt='' style={{ maxWidth: '90%', maxHeight: '90%' }} src={url} />
    );
  }

  return (
    <div className={classes.upload}>
      <input
        accept='image/*'
        style={{ display: 'none' }}
        id='raised-button-file'
        multiple
        type='file'
        onChange={onChange}
      />

      <label htmlFor='raised-button-file'>
        <Button component='span' className={classes.button}>
          Upload
        </Button>
      </label>
    </div>
  );
};

const TokenLookupInput = (props) => {
  const { handleSaveClick, label, classes } = props;
  const [value, setValue] = useState(null);
  const [showSync, setShowSync] = useState(false);

  const onTextChange = (e) => {
    let newText = e.target.value;
    if (newText.length === 10 && newText.substring(0, 2) === 'c_') {
      setShowSync(true);
    } else {
      setShowSync(false);
    }
    setValue(newText);
  };

  const onClickSave = () => {
    handleSaveClick(value);
    setShowSync(false);
  };

  return (
    <Box className={classes.tokenLookup}>
      <Grid container direction='row' justifyContent='flex-start'>
        <Grid item>
          <TextField
            required
            id='card-modal-name-required'
            label={label}
            variant='standard'
            value={value || props.defaultValue}
            onChange={onTextChange}
            className={classes.tokenText}
          />
        </Grid>
        <Grid item>
          {showSync ? (
            <IconButton onClick={onClickSave}>
              <SyncIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

const CardForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const card = useSelector((state) => state.selectCard.card);
  const cardForm = useSelector((state) => state.cardForm);
  const cardUpdated = useSelector((state) => state.selectCard.updated);
  const saveMessage = useSelector((state) => state.selectCard.message);
  const { events, eventTypeId, themeId } = cardForm;
  const { editable, enableTokenLookup } = props;
  const tags = useSelector((state) => state.selectCard.tags);
  const [cardId, setCardId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [imgSrc, setImgSrc] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [style, setStyle] = useState('');
  const [units, setUnits] = useState(0);
  const [printable, setPrintable] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const styles = [
    { name: 'humorous', label: 'Humorous' },
    { name: 'simple', label: 'Simple' },
    { name: 'cute', label: 'Cute' },
    { name: 'modern', label: 'Modern' },
    { name: 'colorful', label: 'Colorful' },
    { name: 'traditional', label: 'Traditional' },
  ];

  useEffect(() => {
    setImgSrc(null);
    // if the card id has changed
    if (card?.id !== cardId && card?.id) {
      let args = {
        token: user?.token,
        cardId: card?.id,
      };
      dispatch(fetchCardTags(args));
    }
    setCardId(card?.id);
    setName(card?.name);
    setDescription(card?.description);
    setStyle(card?.style);
    setUnits(card?.units);
    setPrintable(card?.printable);
  }, [card]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMode) {
      setSaveDisabled(themeId === null || style == null);
    }
  }, [editMode, themeId, style]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cardUpdated) {
      setSnackbarOpen(true);
      dispatch({ type: 'card/setUpdated', value: false });
      dispatch(fetchTags({ token: user?.token }));
    }
  }, [cardUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveCardTags = (cardId, tags) => {
    // check for new tags
    let args = {
      cardId: cardId,
      token: user?.token,
      body: {
        tags: tags,
      },
    };
    dispatch(updateCardTags(args));
  };

  const saveCardUpdates = (card, payload, tags) => {
    let args = {
      cardId: card.id,
      token: user?.token,
      body: payload,
    };
    dispatch(updateCard(args));
    dispatch({
      type: 'selectCard/set',
      value: {
        ...card,
        ...payload,
      },
    });
    saveCardTags(card.id, tags);
  };

  const uploadImage = (imgsrc) => {
    let args = {
      token: user?.token,
      body: {
        ...imgsrc,
        admin_user_id: user?.id,
      },
    };
    dispatch(createCard(args));
  };

  const getPayload = () => {
    let payload = {
      theme_id: themeId || card.theme_id,
    };
    payload.style = style;
    payload.printable = printable || false;
    if (name !== '') {
      payload.name = name;
    }
    if (description !== '') {
      payload.description = description;
    }
    return payload;
  };

  const formatTags = () => {
    return tags.map((t) => ({ id: t.id, name: t.name }));
  };

  const onClickSave = () => {
    let payload = getPayload();
    saveCardUpdates(card, payload, formatTags());
    setImgSrc(null);
  };

  const onClickCancel = () => {
    setName(card.name);
    setDescription(card.description);
    setStyle(card?.style);
    setPrintable(card?.printable || false);
    setEditMode(false);
    setImgSrc(null);
  };

  const onTagChange = (event, newValue) => {
    dispatch({ type: 'selectCard/setTags', value: newValue });
  };

  const onClickUpload = ({ target }) => {
    const fileReader = new FileReader();
    const filename = target.files[0].name;
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      setImgSrc(e.target.result);
      setEditMode(true);
      uploadImage({
        imgsrc: e.target.result,
        filename: filename,
      });
    };
  };

  const handleSubmitToken = (value) => {
    let args = {
      token: user?.token,
      cardToken: value,
    };
    dispatch(fetchCardByToken(args));
  };

  const handleEventSelect = (e) => {
    if (props?.onEventSelect) {
      props.onEventSelect(e);
    } else {
      let eventId = e.target.value;
      dispatch({
        type: 'card-form/setEventId',
        value: eventId,
      });
      dispatch(fetchCardThemes({ eventType: eventId, token: user?.token }));
    }
  };

  useEffect(() => {
    if (cardUpdated) {
      setSnackbarOpen(true);
      dispatch({ type: 'selectCard/setUpdated', value: false });
    }
  }, [cardUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container item direction='row' spacing={1}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <ImageUploadContainer
              url={card?.url || imgSrc || ''}
              classes={classes}
              onChange={onClickUpload}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction='column'
            spacing={1}
            alignItems='flex-start'
            className={classes.edit}
          >
            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                {enableTokenLookup ? (
                  <TokenLookupInput
                    label='Card ID'
                    handleSaveClick={handleSubmitToken}
                    classes={classes}
                    defaultValue={card?.token}
                  />
                ) : (
                  <Chip
                    label={
                      <Typography variant='body1'>
                        <b>Token:</b> {card?.token}
                      </Typography>
                    }
                  ></Chip>
                )}
              </Grid>
              {editable ? (
                <Grid item>
                  <EditBar
                    editMode={editMode}
                    saveDisabled={saveDisabled}
                    setEditMode={setEditMode}
                    onClickSave={onClickSave}
                    onClickCancel={onClickCancel}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item className={classes.eventRow}>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={1}
              >
                <Grid item>
                  {editable ? (
                    <EventThemeSelection
                      events={events}
                      eventTypeId={eventTypeId}
                      onEventSelect={handleEventSelect}
                      disabled={!editMode}
                    />
                  ) : (
                    <div>
                      <Chip
                        className={classes.chip}
                        label={
                          <Typography variant='body1'>
                            Event: <b>{card?.event_type}</b>
                          </Typography>
                        }
                      />
                    </div>
                  )}
                </Grid>
                <Grid item>
                  <FormControl className={classes.styleSelect}>
                    <InputLabel>Card Style</InputLabel>
                    <Select
                      value={style || ''}
                      disabled={!editMode}
                      onChange={(e) => setStyle(e.target.value)}
                    >
                      {styles?.map((x, i) => (
                        <MenuItem value={x.name} key={i}>
                          {x.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.inputBoxes}>
              <TextField
                InputLabelProps={{ shrink: name !== '' }}
                id='card-modal-name'
                label='Name'
                variant='outlined'
                value={name || ''}
                disabled={!editMode}
                fullWidth={true}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item className={classes.inputBoxes}>
              <TextField
                className={classes.description}
                InputLabelProps={{ shrink: description !== '' }}
                id='card-modal-desc'
                label='Description'
                value={description || ''}
                disabled={!editMode}
                rows={4}
                fullWidth={true}
                multiline
                variant='outlined'
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item className={classes.inputBoxes}>
              <TagSelect
                onChange={onTagChange}
                disabled={!editMode}
                selectedTags={tags}
              />
            </Grid>
            <Grid item className={classes.eventRow}>
              <CardInventoryRow
                printable={printable}
                setPrintable={setPrintable}
                units={units || 0}
                unitsClassName={
                  editMode ? classes.unitsEnabled : classes.unitsDisabled
                }
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SnackbarAlert
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={saveMessage?.text}
        severity={saveMessage?.severity}
      />
    </div>
  );
};

export { CardForm };
