import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import usStates from '../../constants/usStates.js';
import { EditBar } from '../EditBar';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(1),
  },
  long: {
    width: '35ch',
  },
  medium: {
    width: '25ch',
  },
  short: {
    width: '16ch',
  },
}));

const VendorForm = (props) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [zipHelperText, setZipHelperText] = useState('');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (props.editable) {
      setEditMode(true);
    }
    if (props?.name?.length > 0) {
      setSaveDisabled(false);
    }
    setName(props?.name || '');
    setAddress(props?.address || '');
    setCity(props?.city || '');
    setStateCode(props?.state || '');
    setZipCode(props?.zipcode || '');
    setWebsite(props?.website || '');
    setEmailAddress(props?.email_address || '');
    setPhoneNumber(props?.phone_number || '');
  }, [props]);

  const handleZipCode = (zip) => {
    if (!/^\d{5}(-\d{4})?$/.test(zip)) {
      setSaveDisabled(false);
      setZipHelperText('Enter a valid zip code');
      setZipCode(zip);
    } else {
      setSaveDisabled(true);
      setZipCode(zip);
      setZipHelperText('');
    }
  };

  const handleNameChange = (value) => {
    setName(value);
    setSaveDisabled(value === '');
  };

  const onClickSave = () => {
    let payload = {
      name: name,
      address: address,
      city: city,
      state: stateCode,
      phone_number: phoneNumber,
      website: website,
      email_address: emailAddress,
      active: true,
    };
    if (zipCode !== '') {
      payload.zipcode = parseInt(zipCode);
    }
    props.handleSaveClick(props?.id, payload);
  };

  const onClickCancel = () => {
    setEditMode(false);
  };

  return (
    <Box>
      <EditBar
        editMode={editMode}
        saveDisabled={saveDisabled}
        setEditMode={setEditMode}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
      <Grid container direction='column' spacing={1} alignItems='flex-start'>
        <Grid item>
          <TextField
            className={classes.long}
            required
            size='small'
            id='vendor-name-required'
            label='Name'
            variant='outlined'
            value={name}
            disabled={!editMode}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.long}
            size='small'
            id='vendor-website'
            label='Website'
            variant='outlined'
            value={website}
            disabled={!editMode}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.long}
            size='small'
            id='vendor-address-one'
            label='Address'
            variant='outlined'
            value={address}
            disabled={!editMode}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={1}>
            <Grid item>
              <TextField
                size='small'
                id='vendor-city'
                label='City'
                variant='outlined'
                value={city}
                disabled={!editMode}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                select
                size='small'
                id='vendor-state'
                label='State'
                variant='outlined'
                value={stateCode}
                disabled={!editMode}
                className={classes.short}
                onChange={(e) => setStateCode(e.target.value)}
              >
                {usStates.map((option) => (
                  <MenuItem
                    key={option.abbreviation}
                    value={option.abbreviation}
                  >
                    {option.abbreviation}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                className={classes.short}
                size='small'
                id='vendor-zip'
                label='Zip Code'
                variant='outlined'
                value={zipCode}
                disabled={!editMode}
                onChange={(e) => handleZipCode(e.target.value)}
                helperText={zipHelperText}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={1}>
            <Grid item>
              <TextField
                className={classes.medium}
                size='small'
                id='vendor-email'
                label='Email Address'
                variant='outlined'
                disabled={!editMode}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.short}
                size='small'
                id='vendor-phone'
                label='Phone Number'
                variant='outlined'
                disabled={!editMode}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export { VendorForm };
