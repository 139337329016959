import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { EditBar } from '../EditBar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: 240,
    height: 320,
  },
  paper: {
    height: 180,
    width: 200,
    textAlign: 'center',
  },
  image: {
    width: 200,
    height: 180,
    overflow: 'hidden',
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '9ch',
    },
  },
  button: {
    display: 'inline-block',
    marginTop: 70,
    backgroundColor: '#014062',
    '&:hover': {
      color: '#0771ab',
    },
  },
}));

const OrderItem = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    units: '0',
    price: '0.00',
  });

  useEffect(() => {
    if (props?.item) {
      let _state = {
        units: `${props.item?.units || 0}`,
        price: props.item?.wholesale_cost
          ? `${props.item.wholesale_cost.toFixed(2)}`
          : '0.00',
      };
      setValues(_state);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (prop) => (event) => {
    let newValues = { ...values, [prop]: event.target.value };
    setValues(newValues);
    let item = {
      wholesale_cost: parseFloat(newValues.price),
      units: parseFloat(newValues.units),
    };
    props.onOrderEdit(item);
  };

  const handleSave = () => {
    let item = {
      ...props.item,
      wholesale_cost: parseFloat(values.price),
      units: parseFloat(values.units),
    };
    props.onClickSave(item);
  };

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <EditBar
            editMode={true}
            saveDisabled={false}
            onClickSave={handleSave}
            onClickCancel={props.onClickCancel}
          />
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            {props.item?.url ? (
              <img
                className={classes.image}
                src={props.item.url}
                alt={props.item.url}
              />
            ) : (
              <Button
                size='large'
                variant='contained'
                color='primary'
                onClick={props.onClickEditCard}
                className={classes.button}
                startIcon={<EditIcon />}
                icon
              >
                Edit Card
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid item>
          <form className={classes.form} noValidate autoComplete='off'>
            <TextField
              id='outlined-units'
              label='Units'
              variant='standard'
              value={values.units}
              type='number'
              onChange={handleChange('units')}
              size='small'
            />
            <TextField
              id='outlined-price'
              label='Price'
              variant='standard'
              value={values.price}
              onChange={handleChange('price')}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export { OrderItem };
