import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';
const initialState = {
  cart: [],
  card: {},
  tags: [],
  updated: false,
  message: {},
};

const removeElement = (arr, index) => {
  if (index === 0) {
    return arr.slice(1);
  }
  if (index === arr.length - 1) {
    return arr.slice(0, index);
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

const mergeTags = (oldTags, newTags) => {
  if (oldTags?.length > 0) {
    let lookup = oldTags.reduce((a, v) => ({ ...a, [v.id]: v.name }), {});
    if (newTags?.length > 0) {
      let existingTags = oldTags.filter((x) => x.id !== -1);
      let updatedTags = newTags.filter((x) => !(x.id in lookup));
      return [...existingTags, ...updatedTags];
    }
    return oldTags;
  }
  return newTags;
};

export default function selectCardReducer(state = initialState, action) {
  switch (action.type) {
    case 'selectCard/setUpdated': {
      return {
        ...state,
        updated: action.value,
      };
    }
    case 'selectCard/set': {
      return {
        ...state,
        card: action.value,
      };
    }
    case 'selectCard/setCart': {
      return {
        ...state,
        cart: action.value,
      };
    }
    case 'selectCard/fetchCart/fulfilled': {
      return {
        ...state,
        cart: action.payload.cart,
      };
    }
    case 'selectCard/addToCart': {
      return {
        ...state,
        cart: [...state.cart, action.value],
      };
    }
    case 'selectCard/removeFromCart': {
      return {
        ...state,
        cart: removeElement(state.cart, action.value),
      };
    }
    case 'selectCard/setTags': {
      return {
        ...state,
        tags: action.value,
      };
    }
    case 'selectCard/fetchTags/fulfilled': {
      return {
        ...state,
        tags: action.payload?.tags,
      };
    }
    case 'selectCard/fetch/fulfilled': {
      return {
        ...state,
        card: action.payload,
      };
    }
    case 'selectCard/fetchByToken/fulfilled': {
      return {
        ...state,
        card: action.payload,
      };
    }
    case 'selectCard/updated/fulfilled': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Card Updated!',
          severity: 'success',
        },
      };
    }
    case 'selectCard/updated/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Failed to Update Card!',
          severity: 'error',
        },
      };
    }
    case 'selectCard/updateTags/fulfilled': {
      return {
        ...state,
        tags: mergeTags(state.tags, action.payload?.tags || []),
        updated: true,
        message: {
          text: 'Card Updated!',
          severity: 'success',
        },
      };
    }
    case 'selectCard/create/fulfilled': {
      return {
        ...state,
        card: action.payload,
        updated: true,
        message: {
          text: 'Card Created!',
          severity: 'success',
        },
      };
    }
    case 'selectCard/create/rejected': {
      return {
        ...state,
        card: action.payload,
        updated: true,
        message: {
          text: 'Failed to create card!',
          severity: 'error',
        },
      };
    }
    default:
      return state;
  }
}

export const fetchCart = createAsyncThunk(
  'selectCard/fetchCart',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/cart/${args.taskId}`, {
      headers: headers,
    });
    return response;
  }
);

export const fetchCard = createAsyncThunk('selectCard/fetch', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.get(`/api/v0/cards/${args.cardId}`, {
    headers: headers,
  });
  return response;
});

export const fetchCardByToken = createAsyncThunk(
  'selectCard/fetchByToken',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(
      `/api/v0/cards_by_token/${args.cardToken}`,
      {
        headers: headers,
      }
    );
    return response;
  }
);

export const fetchCardTags = createAsyncThunk(
  'selectCard/fetchTags',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/card_tags/${args.cardId}`, {
      headers: headers,
    });
    return response;
  }
);

export const updateCard = createAsyncThunk(
  'selectCard/update',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/cards/${args.cardId}`,
      args.body,
      { headers: headers }
    );
    return response;
  }
);

export const createCard = createAsyncThunk(
  'selectCard/create',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post('api/v0/create_card', args.body, {
      headers: headers,
    });
    return response;
  }
);

export const updateCardTags = createAsyncThunk(
  'selectCard/updateTags',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/card_tags/${args.cardId}`,
      args.body,
      { headers: headers }
    );
    return response;
  }
);
