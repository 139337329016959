import 'date-fns';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { EditBar } from '../EditBar';

const STATES = [
  { state: 'placed', label: 'Placed' },
  { state: 'shipped', label: 'Shipped' },
  { state: 'received', label: 'Received' },
  { state: 'cancelled', label: 'Cancelled' },
  { state: 'deleted', label: 'Deleted' },
];

const STATE_DATE_LABELS = {
  placed: 'ordered_at',
  shipped: 'shipped_at',
  received: 'received_at',
  cancelled: 'cancelled_at',
  deleted: 'deleted_at',
};

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(1),
  },
  long: {
    width: '35ch',
  },
  medium: {
    width: '25ch',
  },
  short: {
    width: '16ch',
  },
}));

const dateToStr = (x) => {
  return x.toISOString();
};

const PurchaseOrderForm = (props) => {
  const classes = useStyles();
  const [vendor, setVendor] = useState('');
  const [notes, setNotes] = useState('');
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [orderState, setOrderState] = useState('placed');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (props.editable) {
      setEditMode(true);
    }
    setVendor(props?.vendor_id || '');
    setNotes(props?.notes || '');

    let statusDate = props?.updated_at;
    setOrderState(props?.state || 'placed');
    if (statusDate !== null) {
      setUpdatedAt(new Date(statusDate));
    }
  }, [props]);

  const handleVendorChange = (value) => {
    setVendor(value);
  };

  const onClickSave = () => {
    let payload = {
      vendor_id: vendor,
      notes: notes,
      state: orderState,
    };
    payload[STATE_DATE_LABELS[orderState]] = dateToStr(updatedAt);
    props.handleSaveClick(props?.id, payload);
  };

  const onClickCancel = () => {
    setEditMode(false);
  };

  return (
    <Box>
      <EditBar
        editMode={editMode}
        saveDisabled={false}
        setEditMode={setEditMode}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
      <Grid container direction='column' spacing={1} alignItems='flex-start'>
        <Grid item>
          <TextField
            className={classes.long}
            required
            size='small'
            id='vendor-name-required'
            label='Vendor'
            variant='standard'
            value={vendor}
            disabled={!editMode}
            select
            onChange={(e) => handleVendorChange(e.target.value)}
          >
            {props.vendors.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <Grid container justifyContent='space-around' alignItems='center'>
            <TextField
              className={classes.long}
              required
              size='small'
              id='vendor-name-required'
              label='Order State'
              variant='standard'
              value={orderState}
              disabled={!editMode}
              select
              onChange={(e) => setOrderState(e.target.value)}
            >
              {STATES.map((option) => (
                <MenuItem key={option.state} value={option.state}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                style={{ width: '100%' }}
                disabled={!editMode}
                variant='inline'
                format='MM/dd/yyyy'
                margin='normal'
                id='date-picker-inline'
                label='Date'
                value={updatedAt}
                onChange={setUpdatedAt}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <TextField
            className={classes.long}
            size='small'
            id='vendor-website'
            label='Notes'
            variant='standard'
            value={notes}
            disabled={!editMode}
            multiline
            minRows={4}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export { PurchaseOrderForm };
