import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteModal } from '../DeleteModal';
import { updatePurchaseOrder } from '../../features/purchase-orders/purchaseOrderSlice';

const selectDate = (item) => {
  switch (item?.state) {
    case 'placed': {
      return item?.ordered_at;
    }
    case 'shipped': {
      return item?.shipped_at;
    }
    case 'received': {
      return item?.received_at;
    }
    case 'cancelled': {
      return item?.cancelled_at;
    }
    case 'deleted': {
      return item?.deleted_at;
    }
    default:
      return null;
  }
};

const columns = [
  {
    field: 'vendor_name',
    headerName: 'Vendor',
    width: 200,
  },
  {
    field: 'state',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    width: 200,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 300,
  },
];

const useStyles = makeStyles((theme) => ({
  remove: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#014062',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const PurchaseOrderTable = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [selections, setSelections] = useState([]);
  const [ordersToRemove, setOrdersToRemove] = useState([]);
  const [open, setOpen] = useState(false);

  const handleModalClose = () => {
    setOpen(false);
  };

  const onClickCancel = () => {
    setOrdersToRemove([]);
    setOpen(false);
  };

  const onConfirmRemove = () => {
    ordersToRemove.forEach((x) => {
      dispatch(
        updatePurchaseOrder({
          token: props.user?.token,
          purchaseOrderId: x,
          payload: {
            state: 'deleted',
          },
        })
      );
    });
    setOpen(false);
  };

  const checkSearchText = (input) => {
    if (searchText) {
      return input.toLowerCase().includes(searchText);
    }
    return true;
  };

  const parseRows = (rows) => {
    return rows
      .map((r) => ({
        id: r?.id,
        vendor_name: r?.vendor_name,
        updated_at: new Date(selectDate(r)),
        notes: r?.notes,
        state: r?.state,
        vendor_id: r?.vendor_id,
      }))
      .filter((r) => checkSearchText(r.vendor_name));
  };

  const onRemoveClick = () => {
    setOrdersToRemove(selections);
    setOpen(true);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DeleteModal
        open={open}
        handleClose={handleModalClose}
        onClickCancel={onClickCancel}
        onClickRemove={onConfirmRemove}
        modalClassName={classes.modal}
        message='Remove these PurchaseOrders?'
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <TextField
            placeholder='Search by Vendor'
            variant='outlined'
            style={{ margin: 6 }}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={selections.length === 0}
            onClick={onRemoveClick}
            className={classes.remove}
          >
            Delete Purchase Order?
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={props.onAddNewClick}
          >
            New Order
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        rows={parseRows(props.rows)}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(e) => setSelections(e)}
        onRowClick={props.rowClickEvent}
      />
    </div>
  );
};

export { PurchaseOrderTable };
