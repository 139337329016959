import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { SenderDrawer } from '../components/recipients/SenderDrawer';
import { RecipientInfo } from '../components/recipients/RecipientInfo';
import { fetchRecipientList } from '../features/recipient/recipientSlice';
import { fetchSenderList } from '../features/sender/senderSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  main: {
    flexGrow: 1,
  },
}));

const CustomerView = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const senders = useSelector((state) => state.sender.senderList);
  const recipients = useSelector((state) => state.recipient.recipientList);

  useEffect(() => {
    if (user?.token) {
      let args = {
        token: user?.token,
        page: 1,
      };
      dispatch(fetchSenderList(args));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = (sender, event) => {
    if (user?.token) {
      let args = {
        token: user?.token,
        body: {
          ids: sender?.recipients || [],
        },
      };
      dispatch(fetchRecipientList(args));
    }
  };

  return (
    <Box className={classes.root}>
      <SenderDrawer onClick={onClick} senders={senders} />
      <Box component='main' className={classes.main}>
        {recipients.map((recipient, index) => {
          return (
            <RecipientInfo
              recipient={recipient}
              orientation='row'
              key={index}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomerView;
