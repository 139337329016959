import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: 240,
      boxSizing: 'border-box',
    },
  },
  drawerItems: {
    overflow: 'auto',
    marginTop: 64,
  },
  listItem: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  sender: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1, 1),
    width: '100%',
  },
  counts: {
    position: 'relative',
    float: 'right',
    top: 0,
    right: 0,
  },
}));

const SenderItem = (props) => {
  return (
    <Box className={props.classes.sender}>
      <Badge
        badgeContent={props.recipients?.length || 0}
        color='primary'
        className={props.classes.counts}
      >
        <MailIcon />
      </Badge>
      <Typography variant='body1'>{props.username}</Typography>
      <Typography variant='caption'>{props.email}</Typography>

      <Divider component='li' />
    </Box>
  );
};

const SenderDrawer = (props) => {
  const classes = useStyles();
  const { senders } = props;

  const listItems = () => {
    return senders.map((item, index) => (
      <ListItem
        button
        key={index}
        className={classes.listItem}
        onClick={props.onClick.bind(null, item)}
      >
        <SenderItem classes={classes} key={index} {...item} />
      </ListItem>
    ));
  };

  return (
    <Drawer variant='permanent' className={classes.drawer}>
      <Box className={classes.drawerItems}>
        <List>{listItems()}</List>
      </Box>
    </Drawer>
  );
};

export { SenderDrawer };
