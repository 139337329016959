import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  created: false,
  updated: false,
};

export default function adminUsersReducer(state = initialState, action) {
  switch (action.type) {
    case 'admin-users/setCreated': {
      return {
        ...state,
        created: action.value,
      };
    }
    case 'admin-users/setUpdated': {
      return {
        ...state,
        updated: action.value,
      };
    }
    case 'admin-users/fetch/fulfilled': {
      return {
        ...state,
        users: action.payload,
      };
    }
    case 'admin-users/create/fulfilled': {
      return {
        ...state,
        created: action.payload?.message === 'success',
      };
    }
    case 'admin-users/update/fulfilled': {
      return {
        ...state,
        updated: action.payload?.message === 'success',
      };
    }
    default:
      return state;
  }
}

export const fetchAdminUsers = createAsyncThunk(
  'admin-users/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/users/${args.page}`, {
      headers: headers,
    });
    return response.data;
  }
);

export const createAdminUser = createAsyncThunk(
  'admin-users/create',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      '/api/v0/create_admin_user',
      args.payload,
      { headers: headers }
    );
    return response;
  }
);

export const updateAdminUser = createAsyncThunk(
  'admin-users/update',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/admin_users/${args.adminUserId}`,
      args.payload,
      { headers: headers }
    );
    return response;
  }
);
