import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const CARD_STYLE_LABELS = {
  simple: 'Simple',
  humorous: 'Humorous',
  cute: 'Cute',
  modern: 'Modern',
  colorful: 'Colorful',
  traditional: 'Traditional',
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    overflow: 'wrap',
  },
  h6: {
    fontSize: '1rem',
  },
  tags: {
    textAlign: 'left',
  },
  chips: {
    color: 'white',
    backgroundColor: '#014062',
    borderRadius: 12,
    marginTop: 2,
    marginBottom: 2,
    padding: theme.spacing(1),
  },
}));

const RecipientCardPreferences = (props) => {
  const classes = useStyles();
  const { rankings } = props;

  const getLabel = (style) => {
    if (!(style in CARD_STYLE_LABELS)) {
      return CARD_STYLE_LABELS[style];
    }
    return style[0].toUpperCase() + style.substring(1);
  };
  return (
    <div className={classes.root} style={{ width: props?.width || '100%' }}>
      <Typography variant='h6' className={classes.h6}>
        <b>Card Style</b>
      </Typography>
      <div className={classes.tags}>
        {rankings.map((x, index) => {
          return (
            <Chip
              size='small'
              key={index}
              label={`${index + 1}. ${getLabel(x.style)}`}
              className={classes.chips}
            />
          );
        })}
      </div>
    </div>
  );
};

export { RecipientCardPreferences };
