import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AdminPortalAppBar } from './components/nav/AdminPortalAppBar';
import CustomerView from './views/CustomerView';
import { LoginView } from './views/LoginView';
import { TaskView } from './views/TaskView';
import { RecommendationView } from './views/RecommendationView';
import { AdminUserView } from './views/AdminUserView';
import { initializeFirebase } from './utils/firebaseHelper';
import { getAuth, getIdToken } from 'firebase/auth';
import { Redirect, PrivateRoute } from './utils/routes';
import { fetchUser } from './features/auth/authSlice';
import PATHS from './constants/paths';

import './App.css';

initializeFirebase();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function App() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = getAuth();
  const { loggedIn, status } = useSelector((state) => state.auth);

  useEffect(() => {
    if (status === 'failed') {
      auth.signOut();
    }

    auth.onAuthStateChanged((user) => {
      if (user?.email !== undefined && user?.email !== 'empty') {
        getIdToken(user)
          .then((token) => {
            let body = {
              email_address: user?.email,
              token: token,
            };
            dispatch(fetchUser(body));
          })
          .catch(() => console.log('Authentication Failed!'));
      }
    });
  }, [auth, status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <div className={classes.root}>
        {loggedIn ? <AdminPortalAppBar paths={PATHS} /> : null}
        <Switch>
          <Route exact path='/'>
            {loggedIn ? (
              <Redirect to={PATHS.tasks} />
            ) : (
              <Redirect to={PATHS.login} />
            )}
          </Route>
          <Route path={PATHS.login}>
            <LoginView loggedIn={loggedIn} />
          </Route>
          <PrivateRoute path={PATHS.recommendations} loggedIn={loggedIn}>
            <RecommendationView />
          </PrivateRoute>
          <PrivateRoute path={PATHS.profile} loggedIn={loggedIn}>
            <AdminUserView />
          </PrivateRoute>
          <PrivateRoute path={PATHS.customers} loggedIn={loggedIn}>
            <CustomerView />
          </PrivateRoute>
          <PrivateRoute path={PATHS.tasks} loggedIn={loggedIn}>
            <TaskView />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
