import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { VendorForm } from './VendorForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '50%',
    height: '40%',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    margin: theme.spacing(6),
    left: '15%',
  },
}));

const VendorModal = (props) => {
  const classes = useStyles();

  const { open, editable, handleClose, handleSave, vendor } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.root}>
        <VendorForm
          editable={editable}
          handleSaveClick={handleSave}
          {...vendor}
        />
      </div>
    </Modal>
  );
};

export { VendorModal };
