import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { createOrderItem } from '../../features/purchase-orders/orderItemsSlice';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const PurchaseOrderHeader = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAddNewItemClick = () => {
    let args = {
      token: props.token,
      purchaseOrderId: props.purchaseOrderId,
      payload: {
        units: 0,
        wholesale_cost: 0,
      },
    };
    dispatch(createOrderItem(args));
  };

  return (
    <Grid
      container
      direction='row'
      alignItems='flex-end'
      justifyContent='space-between'
    >
      <Grid item>
        <Typography variant='h6'>
          {props.hasOrder ? '' : <i>Please Select a Purchase Order</i>}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={props.onSaveAllClick}
        >
          Save All Changes
        </Button>
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={handleAddNewItemClick}
        >
          New Item
        </Button>
      </Grid>
    </Grid>
  );
};
export { PurchaseOrderHeader };
