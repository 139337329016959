import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DeleteModal } from '../DeleteModal';
import { fetchTaskTable } from '../../features/task-table/taskTableSlice';
import { updateTask } from '../../features/task/taskSlice';
import { TaskTable } from './TaskTable';
import { SnackbarAlert } from '../SnackbarAlert';
import TASK_STATES from '../../constants/states.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tab: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  radio: {
    padding: theme.spacing(1),
  },
  button: {
    backgroundColor: '#014062',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
  label: {
    fontSize: 12,
  },
}));

const fetchState = (index) => {
  switch (index) {
    case 1: {
      return TASK_STATES.COMPLETED;
    }
    case 2: {
      return TASK_STATES.SHIPPED;
    }
    case 3: {
      return TASK_STATES.CANCELLED;
    }
    default:
      return TASK_STATES.IN_PROGRESS;
  }
};

const getTaskStateRadioOptions = (state) => {
  switch (state) {
    case TASK_STATES.COMPLETED: {
      return [
        { value: TASK_STATES.IN_PROGRESS, label: 'In Progress' },
        { value: TASK_STATES.SHIPPED, label: 'Shipped' },
        { value: TASK_STATES.CANCELLED, label: 'Cancelled' },
      ];
    }
    case TASK_STATES.SHIPPED: {
      return [
        { value: TASK_STATES.IN_PROGRESS, label: 'In Progress' },
        { value: TASK_STATES.COMPLETED, label: 'Completed' },
        { value: TASK_STATES.CANCELLED, label: 'Cancelled' },
      ];
    }
    case TASK_STATES.CANCELLED: {
      return [
        { value: TASK_STATES.IN_PROGRESS, label: 'In Progress' },
        { value: TASK_STATES.COMPLETED, label: 'Completed' },
        { value: TASK_STATES.SHIPPED, label: 'Shipped' },
      ];
    }
    default:
      return [
        { value: TASK_STATES.COMPLETED, label: 'Completed' },
        { value: TASK_STATES.SHIPPED, label: 'Shipped' },
        { value: TASK_STATES.CANCELLED, label: 'Cancelled' },
      ];
  }
};

const TaskStateSelector = (props) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='flex-end'
    >
      <Grid item>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Task Status</FormLabel>
          <RadioGroup
            row
            aria-label='task-status'
            name='taskStatus'
            value={props.status}
            onChange={(e) => props.setStatus(e.target.value)}
          >
            {getTaskStateRadioOptions(props.tab).map((x, i) => (
              <FormControlLabel
                key={i}
                value={x.value}
                control={<Radio size='small' />}
                label={x.label}
                className={props.classes.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Button onClick={props.onClickUpdate} className={props.classes.button}>
          Update Task?
        </Button>
      </Grid>
    </Grid>
  );
};

const TabPanel = (props) => {
  const { children, value, index, tableProps, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <TaskTable {...tableProps} />}
    </div>
  );
};

const tabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const AllTaskView = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const rows = useSelector((state) => state.tasktable);
  const taskUpdated = useSelector((state) => state.task.updated);
  const taskMessage = useSelector((state) => state.task.message);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState(TASK_STATES.IN_PROGRESS);
  const [selections, setSelections] = useState([]);
  const [tasksToUpdate, setTasksToUpdate] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchTaskRows = (page, taskState) => {
    let payload = {
      page: page,
      token: user.token,
      status: taskState,
    };
    if (props?.userOnly) {
      payload['admin_user_id'] = user.id;
    }
    dispatch(fetchTaskTable(payload));
  };

  useEffect(() => {
    if (user?.token) {
      fetchTaskRows(1, fetchState(value));
      dispatch({ type: 'selectCard/set', value: {} });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (taskUpdated) {
      setSnackbarOpen(true);
      fetchTaskRows(1, fetchState(value));
      dispatch({ type: 'task/setUpdated', value: false });
    }
  }, [taskUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchTaskRows(1, fetchState(newValue));
  };

  const handlePageChange = (page) => {
    fetchTaskRows(page, fetchState(value));
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const onClickCancel = () => {
    setTasksToUpdate([]);
    setOpen(false);
  };

  const onConfirmUpdate = () => {
    tasksToUpdate.forEach((x) => {
      let args = {
        token: user?.token,
        taskId: x.id,
        payload: {
          state: status,
        },
      };
      dispatch(updateTask(args));
    });
    setOpen(false);
  };

  const getStateProps = (tab) => {
    return {
      tab: fetchState(tab),
      status: status,
      setStatus: setStatus,
      classes: {
        button: classes.button,
        root: classes.radio,
        label: classes.label,
      },
      onClickUpdate: onUpdateClick,
    };
  };

  const getTableProps = () => {
    let params = {
      taskId: 'taskId',
      token: user?.token,
      rows: rows.map((r, i) => ({ ...r, taskId: r.id, id: i })),
      assigned: true,
      height: 500,
      pageSize: 100,
      pagination: true,
      onPageChange: handlePageChange,
    };
    if (!props.userOnly) {
      params = {
        ...params,
        extraProps: {
          checkboxSelection: true,
          onSelectionModelChange: (e) => setSelections(e),
        },
      };
    }
    return params;
  };

  const onUpdateClick = () => {
    setTasksToUpdate(selections.map((s) => rows[s]));
    setOpen(true);
  };

  const getModalMessage = () => {
    if (status === TASK_STATES.COMPLETED) {
      return 'Mark these tasks as Completed?';
    }
    if (status === TASK_STATES.SHIPPED) {
      return 'Mark these tasks as Shipped?';
    }
    if (status === TASK_STATES.CANCELLED) {
      return 'Mark these tasks as Cancelled?';
    }
    return 'Mark these tasks as In Progress?';
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box className={classes.root}>
      {props?.userOnly ? null : (
        <Box className={classes.radio}>
          <DeleteModal
            open={open}
            handleClose={handleModalClose}
            onClickCancel={onClickCancel}
            onClickRemove={onConfirmUpdate}
            modalClassName={classes.modal}
            message={getModalMessage()}
          />
          <TaskStateSelector {...getStateProps(value)} />
          <SnackbarAlert
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            message={taskMessage?.text}
            severity={taskMessage?.severity}
          />
        </Box>
      )}
      <Box className={classes.tab}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='In Progress' {...tabProps(0)} />
          <Tab label='Completed' {...tabProps(1)} />
          <Tab label='Shipped' {...tabProps(2)} />
          <Tab label='Cancelled' {...tabProps(3)} />
        </Tabs>
      </Box>
      <TabPanel
        value={value}
        index={0}
        tableProps={getTableProps()}
        stateProps={getStateProps()}
      >
        In Progress
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        tableProps={getTableProps()}
        stateProps={getStateProps()}
      >
        Completed
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        tableProps={getTableProps()}
        stateProps={getStateProps()}
      >
        Shipped
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        tableProps={getTableProps()}
        stateProps={getStateProps()}
      >
        Cancelled
      </TabPanel>
    </Box>
  );
};

export { AllTaskView };
