import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  order: {},
  purchaseOrders: [],
  created: false,
  updated: false,
  message: {},
};

export default function purchaseOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case 'purchase-order/set': {
      return {
        ...state,
        order: action.value,
      };
    }
    case 'purchase-order/setCreated': {
      return {
        ...state,
        created: action.value,
      };
    }
    case 'purchase-order/setUpdated': {
      return {
        ...state,
        updated: action.value,
      };
    }
    case 'purchase-order/fetch/fulfilled': {
      return {
        ...state,
        purchaseOrders: action.payload,
      };
    }
    case 'purchase-order/update/fulfilled': {
      return {
        ...state,
        updated: action.payload?.message === 'success',
        message: {
          text: 'Order Updated!',
          severity: 'success',
        },
      };
    }
    case 'purchase-order/create/fulfilled': {
      return {
        ...state,
        created: action.payload?.message === 'success',
        message: {
          text: 'Order Created!',
          severity: 'success',
        },
      };
    }
    case 'purchase-order/update/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Failed to Update Order!',
          severity: 'error',
        },
      };
    }
    case 'purchase-order/create/rejected': {
      return {
        ...state,
        created: true,
        message: {
          text: 'Failed to Create Order!',
          severity: 'error',
        },
      };
    }
    default:
      return state;
  }
}

export const fetchPurchaseOrders = createAsyncThunk(
  'purchase-order/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(`/api/v0/purchase_orders`, {
      headers: headers,
    });
    return response.data;
  }
);

export const updatePurchaseOrder = createAsyncThunk(
  'purchase-order/update',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      `/api/v0/purchase_order/${args.purchaseOrderId}`,
      args.payload,
      {
        headers: headers,
      }
    );
    return response;
  }
);

export const createPurchaseOrder = createAsyncThunk(
  'purchase-order/create',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.post(
      '/api/v0/create_purchase_order',
      args.payload,
      {
        headers: headers,
      }
    );
    return response;
  }
);
