import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Redirect as BaseRedirect, Route } from 'react-router-dom';

import PATHS from '../constants/paths';

const Redirect = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'auth/setLoading', value: false });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <BaseRedirect {...props} />;
};

const PrivateRoute = (props) => {
  return (
    <Route path={props.path}>
      {props.loggedIn ? null : <Redirect to={PATHS.login} />}
      {props.children}
    </Route>
  );
};

export { Redirect, PrivateRoute };
