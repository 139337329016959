import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteModal } from '../DeleteModal';
import { updateAdminUser } from '../../features/admin-users/adminUserSlice';

const USER_GROUPS = {
  admin: '1',
  employee: '2',
};

const columns = [
  {
    field: 'last_name',
    headerName: 'Last Name',
    width: 150,
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    width: 150,
  },
  {
    field: 'email_address',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
  },
  {
    field: 'user_group',
    headerName: 'Group',
    width: 150,
  },
];

const useStyles = makeStyles((theme) => ({
  update: {
    backgroundColor: '#014062',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

const AdminUserTable = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [selections, setSelections] = useState([]);
  const [usersToUpdate, setUsersToUpdate] = useState([]);
  const [open, setOpen] = useState(false);
  const [access, setAccess] = useState(USER_GROUPS.employee);

  const handleModalClose = () => {
    setOpen(false);
  };

  const onClickCancel = () => {
    setUsersToUpdate([]);
    setOpen(false);
  };

  const _getUpdatePayload = (value) => {
    switch (value) {
      case 'remove': {
        return {
          active: false,
        };
      }
      default:
        return {
          active: true,
          user_group_id: value === USER_GROUPS.employee ? 2 : 1,
        };
    }
  };

  const onConfirmUpdate = () => {
    let updatePayload = _getUpdatePayload(access);

    usersToUpdate.forEach((x) => {
      let args = {
        token: props.user?.token,
        adminUserId: x.id,
        payload: {
          email_address: x?.email_address,
          user_group_id: x?.user_group_id,
          ...updatePayload,
        },
      };
      dispatch(updateAdminUser(args));
    });
    setOpen(false);
  };

  const checkSearchText = (input) => {
    if (searchText) {
      return input.toLowerCase().includes(searchText);
    }
    return true;
  };

  const parseRows = (rows) => {
    return rows
      .map((r, i) => ({
        ...r,
        user_id: r.id,
        id: i,
        user_group: r.user_group_id === 1 ? 'Admin' : 'Employee',
      }))
      .filter((r) => checkSearchText(r.last_name));
  };

  const onUpdateClick = () => {
    setUsersToUpdate(selections.map((s) => props.rows[s]));
    setOpen(true);
  };

  const onRadioAccessChange = (e) => {
    setAccess(e.target.value);
  };

  const getModalMessage = () => {
    if (access === 'remove') {
      return 'Remove access for these users?';
    }
    if (access === USER_GROUPS.employee) {
      return 'Set these users to employee access?';
    }
    return 'Grant these users admin access?';
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DeleteModal
        open={open}
        handleClose={handleModalClose}
        onClickCancel={onClickCancel}
        onClickRemove={onConfirmUpdate}
        message={getModalMessage()}
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <TextField
            placeholder='Search by Last Name'
            variant='outlined'
            style={{ margin: 6 }}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid container direction='row' alignItems='center'>
            <Grid item>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Update Access</FormLabel>
                <RadioGroup
                  row
                  aria-label='access'
                  name='userAccess'
                  value={access}
                  onChange={onRadioAccessChange}
                >
                  <FormControlLabel
                    value={USER_GROUPS.employee}
                    control={<Radio />}
                    label='Employee'
                  />
                  <FormControlLabel
                    value={USER_GROUPS.admin}
                    control={<Radio />}
                    label='Admin'
                  />
                  <FormControlLabel
                    value={'remove'}
                    control={<Radio />}
                    label='Remove'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                disabled={selections.length === 0}
                onClick={onUpdateClick}
                className={classes.update}
              >
                Update Access?
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        rows={parseRows(props.rows)}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(e) => setSelections(e)}
      />
    </div>
  );
};

export { AdminUserTable };
