import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '../logo.svg';
import { getAuth, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 32,
  },
  logo: {
    height: 200,
    width: 200,
    display: 'block',
    margin: 'auto',
    fill: 'rgb(1, 64, 98)',
    paddingLeft: 30,
  },
  login: {
    backgroundColor: 'rgb(1, 64, 98)',
  },
}));

const LoginView = (props) => {
  const auth = getAuth();
  const classes = useStyles();
  const { loggedIn, loading, redirect } = useSelector((state) => state.auth);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const loginElement = (loading, classes) => {
    if (loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }
    return (
      <Button
        variant='contained'
        color='primary'
        onClick={signInWithGoogle}
        className={classes.login}
      >
        Login With Google
      </Button>
    );
  };

  if (redirect && loggedIn) {
    return <Redirect to='/' />;
  }

  return (
    <div>
      <Container maxWidth='sm' className={classes.root}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Logo className={classes.logo} />
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          {loginElement(loading, classes)}
        </Grid>
      </Container>
    </div>
  );
};

export { LoginView };
