import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  loading: true,
  redirect: false,
  status: 'processing',
  user: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'auth/set': {
      return {
        ...state,
        ...action.value,
      };
    }
    case 'auth/setUser': {
      return {
        ...state,
        user: action.value,
      };
    }
    case 'auth/setLoggedIn': {
      return {
        ...state,
        loggedIn: action.value,
      };
    }
    case 'auth/setLoading': {
      return {
        ...state,
        loading: action.value,
      };
    }
    case 'auth/setRedirect': {
      return {
        ...state,
        redirect: action.value,
      };
    }
    case 'auth/checkAuth/fulfilled': {
      return {
        ...state,
        redirect: true,
        loading: true,
        loggedIn: true,
        status: 'success',
        user: {
          ...action.payload.response.data,
          token: action.payload.token,
        },
      };
    }
    case 'auth/checkAuth/rejected': {
      return {
        ...state,
        status: 'failed',
        loading: false,
      };
    }
    default:
      return state;
  }
}

export const fetchUser = createAsyncThunk('auth/checkAuth', async (body) => {
  const response = await client.post('/api/v0/auth', body);
  return {
    response: response,
    token: body.token,
    email_address: body.email_address,
  };
});
