import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  task: {},
  user: {},
  assigned: false,
  updated: false,
  message: {},
};

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case 'task/setTask': {
      return {
        ...state,
        ...action.value,
      };
    }
    case 'task/setTaskUser': {
      return {
        ...state,
        user: action.value,
      };
    }
    case 'task/setAssigned': {
      return {
        ...state,
        assigned: action.value,
      };
    }
    case 'task/setUpdated': {
      return {
        ...state,
        updated: action.value,
      };
    }
    case 'task/setMessage': {
      return {
        ...state,
        message: action.value,
      };
    }
    case 'task/reassign/fulfilled': {
      return {
        ...state,
        assigned: action.payload?.message === 'success' ? false : true,
      };
    }
    case 'task/assign/fulfilled': {
      return {
        ...state,
        assigned: action.payload?.message === 'success',
      };
    }
    case 'task/update/fulfilled': {
      return {
        ...state,
        updated: action.payload?.message === 'success',
        message: {
          text: 'Task Saved!',
          severity: 'success',
        },
      };
    }
    case 'task/update/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Error! Unable to Save.',
          severity: 'error',
        },
      };
    }
    default:
      return state;
  }
}

export const assignTask = createAsyncThunk('task/assign', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.post(
    `/api/v0/assign_task/${args.taskId}`,
    {
      admin_user_id: args.adminUserId,
    },
    { headers: headers }
  );
  return response;
});

export const updateTask = createAsyncThunk('task/update', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.post(
    `/api/v0/task/${args.taskId}`,
    args.payload,
    { headers: headers }
  );
  return response;
});

export const reassignTask = createAsyncThunk('task/reassign', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.post(
    `/api/v0/assign_task/${args.taskId}`,
    {
      admin_user_id: args.adminUserId,
      reassign: true,
    },
    { headers: headers }
  );
  return response;
});
