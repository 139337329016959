import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  vendors: [],
  updated: false,
  message: {},
};

export default function vendorsReducer(state = initialState, action) {
  switch (action.type) {
    case 'vendors/setUpdated': {
      return {
        ...state,
        updated: action.value,
      };
    }
    case 'vendors/fetch/fulfilled': {
      return {
        ...state,
        vendors: action.payload,
      };
    }
    case 'vendors/update/fulfilled': {
      return {
        ...state,
        updated: action.payload?.message === 'success',
        message: {
          text: 'Vendor Updated!',
          severity: 'success',
        },
      };
    }
    case 'vendors/create/fulfilled': {
      return {
        ...state,
        updated: action.payload?.message === 'success',
        message: {
          text: 'Vendor Created!',
          severity: 'success',
        },
      };
    }
    case 'vendors/update/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Failed to Update Vendor!',
          severity: 'success',
        },
      };
    }
    case 'vendors/create/rejected': {
      return {
        ...state,
        updated: true,
        message: {
          text: 'Failed to Create Vendor!',
          severity: 'error',
        },
      };
    }
    default:
      return state;
  }
}

export const fetchVendors = createAsyncThunk('vendors/fetch', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.get(`/api/v0/vendors`, { headers: headers });
  return response.data;
});

export const updateVendor = createAsyncThunk('vendors/update', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.post(
    `/api/v0/vendor/${args.vendorId}`,
    args.payload,
    {
      headers: headers,
    }
  );
  return response;
});

export const createVendor = createAsyncThunk('vendors/create', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const response = await client.post('/api/v0/create_vendor', args.payload, {
    headers: headers,
  });
  return response;
});
