import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  recipient: {},
  recipientList: [],
  message: {},
  status: 'pending',
};

export default function recipientReducer(state = initialState, action) {
  switch (action.type) {
    case 'recipient/setPending': {
      return { ...state, status: 'pending', message: {} };
    }
    case 'recipient/fetch/fulfilled': {
      return { ...state, recipient: action.payload };
    }
    case 'recipient/fetchList/fulfilled': {
      return { ...state, recipientList: action.payload };
    }
    case 'recipient/update/fulfilled': {
      return {
        ...state,
        recipient: action.payload,
        status: 'succeeded',
        message: {
          text: 'Successfully Updated the Recipient',
          severity: 'success',
        },
      };
    }
    case 'recipient/update/rejected': {
      return {
        ...state,
        status: 'rejected',
        message: { text: 'Failed to Update the Recipient', severity: 'error' },
      };
    }
    case 'recipient/updateCard/fulfilled': {
      return {
        ...state,
        recipient: action.payload,
        status: 'succeeded',
        message: {
          text: 'Successfully Updated the Card',
          severity: 'success',
        },
      };
    }
    case 'recipient/updateCard/rejected': {
      return {
        ...state,
        status: 'rejected',
        message: {
          text: 'Failed to Update the Card',
          severity: 'error',
        },
      };
    }
    default:
      return state;
  }
}

export const fetchRecipientById = createAsyncThunk(
  'recipient/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const urlPrefix = `/api/v0/recipients/${args.recipientId}`;
    const url = args.cardId ? `${urlPrefix}/${args.cardId}` : urlPrefix;
    const response = await client.get(url, { headers: headers });
    return response;
  }
);

export const fetchRecipientList = createAsyncThunk(
  'recipient/fetchList',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const response = await client.post(`/api/v0/active_recipients`, args.body, {
      headers: headers,
    });
    return response.data;
  }
);

export const updateRecipientById = createAsyncThunk(
  'recipient/update',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const response = await client.post(
      `/api/v0/recipients/update/${args.recipientId}`,
      args.body,
      {
        headers: headers,
      }
    );
    return response;
  }
);

export const updateRecipientCardById = createAsyncThunk(
  'recipient/updateCard',
  async (args) => {
    const headers = {
      authorization: args.token,
    };

    const response = await client.post(
      `/api/v0/recipients/update-card/${args.recipientId}/${args.cardId}`,
      args.body,
      {
        headers: headers,
      }
    );
    return response;
  }
);
