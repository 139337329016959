import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Grid from '@material-ui/core/Grid';
import { CardModal } from '../cards/CardModal';
import { OrderItem } from './OrderItem';
import { PurchaseOrderHeader } from './PurchaseOrderHeader';
import { DeleteModal } from '../DeleteModal';
import {
  fetchOrderItems,
  updateOrderItem,
  deleteOrderItem,
} from '../../features/purchase-orders/orderItemsSlice';
import { fetchCard } from '../../features/select-card/selectCardSlice';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  imageList: {
    width: '100%',
    overflow: 'scroll',
    height: 400,
  },
}));

const OrderItemsGallery = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const card = useSelector((state) => state.selectCard.card);
  const orders = useSelector((state) => state.orderItems.orderItems);
  const orderCreated = useSelector((state) => state.orderItems.created);
  const orderDeleted = useSelector((state) => state.orderItems.deleted);
  const [focusOrderIndex, setFocusOrderIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const _fetchOrderItems = (token, purchaseOrderId) => {
    let args = {
      token: token,
      purchaseOrderId: purchaseOrderId,
    };
    dispatch(fetchOrderItems(args));
  };

  useEffect(() => {
    if (props?.purchaseOrder?.id) {
      _fetchOrderItems(props.user?.token, props.purchaseOrder.id);
    }
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderCreated) {
      _fetchOrderItems(props.user?.token, props.purchaseOrder.id);
      dispatch({ type: 'order-item/setCreated', value: false });
    }
    if (orderDeleted) {
      _fetchOrderItems(props.user?.token, props.purchaseOrder.id);
      dispatch({ type: 'order-item/setDeleted', value: false });
    }
  }, [orderCreated, orderDeleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalClose = () => {
    setModalOpen(false);
    if (card?.id !== orders[focusOrderIndex]?.card_id && card?.id) {
      let updatedOrders = [...orders];
      updatedOrders[focusOrderIndex] = {
        ...orders[focusOrderIndex],
        card_id: card.id,
        url: card.url.replace('originals', 'thumbnails'),
      };
      dispatch({ type: 'order-item/setOrderItems', value: updatedOrders });
    }
  };

  const handleClickEditCard = (index) => () => {
    setFocusOrderIndex(index);
    if (orders[index]?.card_id) {
      let args = {
        token: props.user?.token,
        cardId: orders[index].card_id,
      };
      dispatch(fetchCard(args));
    } else {
      dispatch({ type: 'selectCard/set', value: {} });
    }
    setModalOpen(true);
  };

  const handleClickSave = (index) => (item) => {
    let payload = { ...item };
    if (orders[index]?.card_id) {
      payload.card_id = orders[index].card_id;
    }

    let args = {
      token: props.user?.token,
      orderItemId: orders[index].id,
      payload: Object.keys(payload)
        .filter((k) => payload[k] !== null)
        .reduce((a, k) => ({ ...a, [k]: payload[k] }), {}),
    };
    dispatch(updateOrderItem(args));
  };

  const handleClickCancel = (index) => () => {
    setDeleteModalOpen(true);
    setFocusOrderIndex(index);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setFocusOrderIndex(null);
  };

  const onDeleteConfirmRemove = () => {
    setDeleteModalOpen(false);
    let args = {
      token: props.user?.token,
      orderItemId: orders[focusOrderIndex].id,
    };
    dispatch(deleteOrderItem(args));
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleSaveAllClick = () => {
    orders.forEach((item, index) => {
      handleClickSave(index)(item);
    });
  };

  const handleOrderEdit = (index) => (item) => {
    let updatedOrders = [...orders];
    updatedOrders[index] = {
      ...orders[index],
      ...item,
    };
    dispatch({ type: 'order-item/setOrderItems', value: updatedOrders });
  };

  return (
    <div className={classes.root}>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        onClickCancel={handleDeleteCancel}
        onClickRemove={onDeleteConfirmRemove}
        message='Delete this item?'
      />
      <CardModal
        open={modalOpen}
        handleClose={handleModalClose}
        enableTokenLookup={true}
        editable={true}
      />
      <Grid
        container
        direction='column'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <Grid item container direction='row'>
          <PurchaseOrderHeader
            token={props.user?.token}
            hasOrder={props.hasOrder}
            purchaseOrderId={props.purchaseOrder.id}
            onSaveAllClick={handleSaveAllClick}
          />
        </Grid>
      </Grid>

      <ImageList rowHeight='auto' cols={4} className={classes.imageList}>
        {orders.map((item, index) => (
          <ImageListItem key={`order_item_${index}`}>
            <OrderItem
              onClickSave={handleClickSave(index)}
              onClickCancel={handleClickCancel(index)}
              onClickEditCard={handleClickEditCard(index)}
              onOrderEdit={handleOrderEdit(index)}
              item={item}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export { OrderItemsGallery };
