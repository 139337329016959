import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export default function assignedTasksReducer(state = initialState, action) {
  switch (action.type) {
    case 'assigned-task/fetch/fulfilled': {
      return action.payload;
    }
    default:
      return state;
  }
}

export const fetchAssignedTasksById = createAsyncThunk(
  'assigned-task/fetch',
  async (args) => {
    const headers = {
      authorization: args.token,
    };
    const response = await client.get(
      `/api/v0/assigned_tasks/1/${args.adminUserId}`,
      { headers: headers }
    );
    return response.data;
  }
);
