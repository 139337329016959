import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { fetchUnassignedTasks } from '../features/unassigned-tasks/unassignedTaskSlice';
import { fetchAssignedTasksById } from '../features/assigned-tasks/assignedTaskSlice';
import { TaskTable } from '../components/tasks/TaskTable';
import PATHS from '../constants/paths';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    margin: 16,
  },
  header: {
    padding: 16,
  },
  activeHeader: {
    padding: 16,
  },
  button: {
    backgroundColor: '#014062',
    '&:hover': {
      backgroundColor: '#0771ab',
    },
  },
}));

export function TaskView() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const unassignedTasks = useSelector((state) => state.unassigned);
  const assignedTasks = useSelector((state) => state.assigned);
  const [rowsState, setRowsState] = useState({ page: 1, pageSize: 50 });

  useEffect(() => {
    if (user?.token) {
      dispatch(fetchUnassignedTasks({ page: 1, token: user.token }));
      dispatch(
        fetchAssignedTasksById({ token: user.token, adminUserId: user.id })
      );
      dispatch({ type: 'selectCard/set', value: {} });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.token) {
      dispatch(
        fetchUnassignedTasks({ page: rowsState.page, token: user.token })
      );
      dispatch({ type: 'selectCard/set', value: {} });
    }
  }, [rowsState]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickChooseCard = () => {
    let randomIndex = Math.floor(unassignedTasks.length * Math.random());
    let actionValue = {
      task: unassignedTasks[randomIndex],
      assigned: false,
    };
    dispatch({ type: 'task/setTask', value: actionValue });
    dispatch({ type: 'selectCard/setCart', value: [] });
    history.push(PATHS.recommendations);
  };

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper} square>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              className={classes.header}
            >
              <Grid item xs={6}>
                <Typography variant='h5'>Open Tasks</Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  size='large'
                  disabled={unassignedTasks.length === 0}
                  variant='contained'
                  color='primary'
                  onClick={clickChooseCard}
                  className={classes.button}
                >
                  Choose a Card!
                </Button>
              </Grid>
            </Grid>
            <TaskTable
              taskId='id'
              token={user?.token}
              adminUserId={user?.id}
              adminUserName='Me'
              rows={unassignedTasks}
              assigned={false}
              height={800}
              extraProps={{
                rowCount: 500,
                pageSize: 100,
                // pagination: true,
                // paginationMode: 'server',
                // page: rowsState.page,
                // onPageChange: (page) =>
                //   setRowsState((prev) => ({ ...prev, page })),
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper} square>
            <Typography variant='h5' className={classes.activeHeader}>
              Your Tasks
            </Typography>
            <TaskTable
              taskId='id'
              height={400}
              token={user?.token}
              adminUserId={user?.id}
              adminUserName='Me'
              rows={assignedTasks}
              assigned={true}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
