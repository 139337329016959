import { client } from '../../utils/fetch';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  tags: [],
  tagsCreated: false,
};

const mergeTags = (oldTags, newTags) => {
  if (newTags.length === 0) {
    return oldTags;
  }
  let lookup = oldTags.reduce((a, v) => ({ ...a, [v.id]: v.name }), {});
  let newItems = newTags.filter((k) => !(k.id in lookup));
  if (newItems.length === 0) {
    return oldTags;
  }
  return [...oldTags, ...newItems];
};

export default function tagsReducer(state = initialState, action) {
  switch (action.type) {
    case 'tags/setTags': {
      return {
        ...state,
        tags: action.value,
      };
    }
    case 'tags/setCreated': {
      return {
        ...state,
        tagsCreated: action.value,
      };
    }
    case 'tags/fetch/fulfilled': {
      return {
        ...state,
        tags: action.payload,
      };
    }
    case 'tags/create/fulfilled': {
      return {
        ...state,
        tagsCreated: true,
      };
    }
    case 'selectCard/updateTags/fulfilled': {
      return {
        ...state,
        tags: mergeTags(state.tags, action.payload?.tags || []),
      };
    }
    default:
      return state;
  }
}

export const fetchTags = createAsyncThunk('tags/fetch', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const url = '/api/v0/tags';
  const response = await client.get(url, { headers: headers });
  return response.data;
});

export const createTags = createAsyncThunk('tags/create', async (args) => {
  const headers = {
    authorization: args.token,
  };
  const url = '/api/v0/create_tags';
  const response = await client.post(url, args.body, { headers: headers });
  return response.data;
});
