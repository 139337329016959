import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { VendorModal } from './VendorModal';
import { VendorTable } from './VendorTable';
import { SnackbarAlert } from '../SnackbarAlert';
import {
  fetchVendors,
  createVendor,
  updateVendor,
} from '../../features/vendors/vendorSlice';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const VendorView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const vendors = useSelector((state) => state.vendors.vendors);
  const vendorUpdated = useSelector((state) => state.vendors.updated);
  const saveMessage = useSelector((state) => state.vendors.message);
  const [open, setOpen] = useState(false);
  const [addVendor, setAddVendor] = useState(false);
  const [focusVendor, setFocusVendor] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (user?.token) {
      dispatch(fetchVendors({ token: user.token }));
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vendorUpdated) {
      setSnackbarOpen(true);
      setOpen(false);
      dispatch(fetchVendors({ token: user?.token }));
      dispatch({ type: 'vendors/setUpdated', value: false });
    }
  }, [vendorUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddNewClick = () => {
    setOpen(true);
    setAddVendor(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddVendor(false);
  };

  const handleSave = (vendorId, payload) => {
    let args = {
      token: user?.token,
      payload: payload,
      vendorId: vendorId,
    };
    if (addVendor) {
      dispatch(createVendor(args));
    } else {
      dispatch(updateVendor(args));
    }
  };

  const rowClickEvent = (params) => {
    setFocusVendor(params?.row || {});
    setOpen(true);
    setAddVendor(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <VendorModal
        open={open}
        editable={addVendor}
        handleClose={handleClose}
        handleSave={handleSave}
        vendor={focusVendor}
      />

      <Paper className={classes.paper}>
        <VendorTable
          user={user}
          rows={vendors}
          onRowClick={rowClickEvent}
          onAddNewClick={onAddNewClick}
        />
      </Paper>
      <SnackbarAlert
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={saveMessage?.text}
        severity={saveMessage?.severity}
      />
    </div>
  );
};

export { VendorView };
