import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SenderForm from './SenderForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '50%',
    height: '40%',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    margin: theme.spacing(6),
    left: '15%',
  },
}));

const SenderModal = ({ open, editable, handleClose, sender, senderId }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.root}>
        <SenderForm senderId={senderId} sender={sender} editable={editable} />
      </div>
    </Modal>
  );
};

export default SenderModal;
